.contact-card{
    padding: calc(16rem/16);
    background-color: #FFF;
    box-shadow: var(--box-shadow-default);

    @media screen and (min-width: 768px){
        padding: calc(57rem/16);

    }
}
