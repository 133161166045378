/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.footnote {
    padding: 0 1.875rem 0.75rem 1.875rem;
    color: #0000004d;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-black { color: #000000; }
.text-color-default { color: var(--color-text-default); }


/* Background Color Helper */
.bg-black { background-color: #000; }


/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

/*::after box-shadow*/
.has-shadow{
    position: relative;
}

.has-shadow::after {
    content: "";
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, .5);
    height: 50%;
    width: 98%;
    position: absolute;
    right: 1%;
    border-radius: 30px;
    top: 50%;
    z-index: -1;
    transform: translateY(-50%);
}
.box-shadow { box-shadow: var(--box-shadow-default); }
.pointer-events-none {pointer-events: none;}
.pointer-events-auto {pointer-events: auto;}
.isCursor { cursor: pointer; }

.fz14 { font-size: calc(14rem/16); }
.fz12 { font-size: calc(12rem/16); }
.fz7 {font-size: calc(7rem/16);}
.lh-1 { line-height:1; }

.min-height-auto { min-height: auto !important;}