.cse-teaser {
    box-shadow: var(--box-shadow-default);
}
.cse-teaser__body {
    background-color:#fff;
    padding: calc(20rem/16) calc(40rem/16);
    height: 100%;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.cse-teaser__hl {
    color: var(--color-primary);
    margin-bottom: calc(20rem/16);
    font-size: calc(20rem/16);
}
.cse-teaser__img {
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
}