html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
    word-break: break-word;
    @media screen and (max-width: 767px) {
        font-size: var(--font-size-default-xs);
    }
}
.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
}

h1, .h1 {
    font-size: calc(50rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(63/50);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        font-size: calc(40rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(28rem/16);
        line-height: calc(35/28);
    }
}
h2, .h2 {
    font-size: calc(45rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(56/45);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        font-size: calc(38rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(28rem/16);
    }
}
h3, .h3 {
    font-size: calc(32rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(40/32);
    @media screen and (max-width: 767px) {
        font-size: calc(22rem/16);
    }
}
h4, .h4 {
    font-size: calc(22rem/16);
    line-height: calc(27rem/16);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
h5, .h5 {
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    text-transform: uppercase;
    letter-spacing: 2.5px;
    @media screen and (max-width: 767px) {
        font-size: calc(13rem/16);
        line-height: calc(16/13);
    }
}
h6, .h6 {
    font-size: calc(14rem/16);
}

table{
    word-break: initial;
}

@media screen and (max-width: 768px) {
    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select:focus,
    textarea {
        font-size: calc(16rem/16);
    }
}
