.wysiwyg-with-medium {
    position: relative;
    padding-top: calc(90rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(20rem/16);
    }
}
.wysiwyg-with-medium:before {
    content:'';
    position: absolute;
    width: 100%;
    max-width: 69%;
    right: 0;
    top:0;
    height:100%;
    background: linear-gradient(180deg, #F6F6F6 0%, #FFFFFF 100%);
    @media screen and (max-width: 767px) {
        max-width: 100%;
    }
}
.wysiwyg-with-medium:after {
    content:'';
    box-shadow: 0 0 60px 0 rgba(0,0,0,0.4);
    height: 40%;
    opacity:0.5;
    width: 69%;
    position: absolute;
    right:0;
    border-radius: 30px;
    top:50%;
    z-index: -1;
    transform: translateY(-50%);

}
.wysiwyg-with-medium--wysiwyg-left.wysiwyg-with-medium:after {
    left:0;
    right:initial;
}
@media screen and (min-width: 768px) {
    .wysiwyg-with-medium .wysiwyg-with-medium__inner {
        padding-right: calc(80rem/16);
    }
    .wysiwyg-with-medium--wysiwyg-left .wysiwyg-with-medium__inner {
        padding-left: calc(80rem/16);
        padding-right:0;
    }
}
.wysiwyg-with-medium--wysiwyg-left:before {
    left:0;
    right: initial;
}
.wysiwyg-with-medium__media {
    position: relative;
}
.wysiwyg-with-medium__zoom-btn {
    position: absolute;
    z-index: 2;
    bottom:0;
    left:0;
    background: var(--gradient-primary);
    color:#fff;
    border: none;
    box-shadow: none;
    width: calc(50rem/16);
    height: calc(50rem/16);
    font-size: calc(20rem/16);
    line-height:1;
    justify-content: center;
    align-items:center;
    pointer-events: none;
    display: none;
    @media screen and (min-width: 768px) {
        transition: opacity 0.2s ease, transform 0.2s ease;
        transform-origin: left bottom;
        opacity: 0;
        display: flex;
        transform: scale(0);
    }
}
.wysiwyg-with-medium__media:hover .wysiwyg-with-medium__zoom-btn {
    @media screen and (min-width: 768px) {
        opacity: 1;
        transform: scale(1);
    }
}
.wysiwyg-with-medium__media:before {
    content:'';
    box-shadow: 0 0 60px 0 rgba(0,0,0,0.4);
    height: 60%;
    width: 100%;
    position: absolute;
    left:0;
    opacity:0.6;
    border-radius: 30px;
    top:50%;
    transform: translateY(-50%);
}