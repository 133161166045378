.lg-backdrop {
    background-color: rgba(0, 0, 0, 0.9);
}
.lg-sub-html {
    color:#fff;
    background-color: transparent;
    padding-bottom: calc(20rem/16);
}
.lightbox-content__title {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}
.lightbox-video__iframe, .lightbox-video__iframe_wrapper .iframe {
    width: calc(845rem/16);
    height: calc(475rem/16);
    @media screen and (max-width: 767px) {
        width: 100%;
        height:auto;
    }
}
.lg-next, .lg-prev, .lg-toolbar {
    background-color: transparent;
    opacity:1;
}
.lg-toolbar .lg-icon {
    color:#fff;
    opacity:1;
    font-size: calc(40rem/16);
}
.lg-outer .lg-video-cont {
    @media screen and (min-width: 1299px) {
        max-width: calc(1140rem/16) !important;
    }
}

.lg-outer .lg-video {
    padding-bottom: 60.25% !important;
}
.lg-outer .lg-video iframe {
    background-color:#fff;
}