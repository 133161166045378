:root {
    --main-navbar-height: calc(100rem / 16);
    --main-navbar-height--xs: calc(50rem / 16);
    --main-navbar-color: #000;
}

.main-navbar {
    z-index: 100;
    color: var(--main-navbar-color);
    position: absolute;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
        flex-flow: row nowrap;
        justify-content: flex-start;
        pointer-events: none;
    }
    @media screen and (max-width: 767px) {
        background-color:#fff;
    }
}
.main-navbar.is-affix {
    color: var(--main-navbar-color);
    position: fixed;
    top:0;
}
.main-navbar--transparent.main-navbar {
    position: absolute;
    width: 100%;
    color: #fff;
    @media screen and (max-width: 767px) {
        background-color:#fff;
        color:#000;
    }
}
.main-navbar--transparent.main-navbar.is-affix {
    top:0;
    color: var(--main-navbar-color);
    position: fixed;
}

.main-navbar--transparent:not(.is-affix):before {
    @media screen and (min-width: 768px) {
        background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
        content:'';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }
}
.main-navbar--fixed {
    position: fixed;
    width: 100%;
    top: 0;
}
.main-navbar__container {
    width: 100%;
    display: flex;
}
.main-navbar__logo-wrapper {
    position: relative;
    pointer-events: auto;
    z-index: 999;
    background-image: url("/static/img/logo/logo-bg.svg");
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    display:flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 1600px) {
        width: calc(327rem/16);
    }
    @media screen and (max-width: 1599px) and (min-width: 768px) {
        width: calc(220rem/16);
        justify-content:flex-start;
    }
    @media screen and (max-width: 767px){
        max-width: 9.75rem;
        height: calc(55rem/16);
    }
    @media screen and (max-width: 360px) {
        display: flex;
        align-items: center;
    }

}
.main-navbar__logo {
    @media screen and (min-width: 1600px) {
        width: calc(252rem/16);
        padding: 5px 60px 0 0;
    }
    @media screen and (max-width: 1599px) and (min-width: 768px) {
        max-width: 70%;
        padding-left: calc(10rem/16);
        padding-top: calc(10rem/16);
    }
    @media screen and (max-width: 767px) and (min-width: 361px){
        max-width: 8.75rem;
        padding: 15px 40px 7px 10px;
    }
    @media screen and (max-width: 360px) {
        max-width: 7.2rem;
        padding: 5px 40px 0px 7px;
    }
}
.main-navbar__ticket-btn {
    background-size: 300% 100%;
    transition: all 0.2s ease-in-out;
    background-image: linear-gradient(to right, #DA001C, #9A0014, #b10017, #ec001e);
    box-shadow: 0 -5px 14px 0 rgba(150,5,23,0.4);
    height: calc(120rem/16);
    color:#fff;
    font-size: calc(18rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-default-bold);
    letter-spacing: calc(2rem/16);
    position: relative;
    text-transform: uppercase;
    padding-right: calc(50rem/16);
    margin-left: calc(90rem/16);
    z-index: 999;
    @media screen and (max-width: 1599px) and (min-width: 1200px) {
        height: calc(78rem/16);
        margin-left: calc(30rem/16);
        padding-right: calc(20rem/16);
        font-size: calc(16rem/16);
    }
    @media screen and (max-width: 1199px) and (min-width: 768px){
        padding-right: calc(5rem/16);
        height: calc(78rem/16);
        margin-left: calc(15rem/16);
        font-size: calc(13rem/16);
        letter-spacing:0;
        padding-left: calc(5rem/16);
        flex-direction: column;
    }
    @media screen and (max-width: 767px) {
        height: calc(55rem/16);
        letter-spacing: 0;
        font-size: calc(9rem/16);
        padding: calc(10rem/16);
        margin-left: calc(5rem/16);
        display: flex;
        text-transform: none;
        flex-direction: column;
        justify-content: space-between;
    }
}
.main-navbar__ticket-btn:hover {
    color: #fff;
}
.main-navbar__ticket-btn:before {
    content:'';
    position: absolute;
    top:0;
    left: calc(-89rem/16);
    width: 0;
    height: 0;
    border-style: solid;
    z-index: 999;
    border-left: calc(90rem / 16) solid transparent;
    border-bottom: calc(120rem / 16) solid var(--color-primary);
    border-right: 0 solid transparent;
    border-top: 0 solid transparent;
    @media screen and (max-width: 1599px) and (min-width: 1200px) {
        border-left: calc(30rem / 16) solid transparent;
        border-bottom: calc(78rem / 16) solid var(--color-primary);
        left: calc(-30rem/16);
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        display: none;
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.main-navbar__ticket-btn__icon {
    font-size: calc(42rem/16);
    padding-right: calc(12rem/16);
    padding-left: calc(12rem/16);
    position: relative;
    z-index: 3;
    @media screen and (max-width: 1599px) and (min-width: 1200px){
        font-size: calc(30rem/16);
        padding-left: 0;
        padding-right: calc(5rem/16);
    }
    @media screen and (max-width: 1199px) and  (min-width: 768px) {
        font-size: calc(30rem/16);
        display: block;
    }
    @media screen and (max-width: 767px) {
        font-size: calc(30rem/16);
        display: block;
        padding:0;
    }
}
.main-navbar__dropdown {
    height: calc(78rem/16);
    box-shadow: none;
    display: flex;
    padding: calc(10rem/16) calc(24rem/16) ;
    font-size: calc(16rem/16);
    justify-content: center;
    line-height:1.8;
    @media screen and (max-width: 767px) {
        height: calc(42rem/16);
    }
}
.main-navbar__dropdown-menu {
    background: rgba(255,255,255,1);
    border-top: calc(2rem/16) solid var(--color-primary);
    width: calc(70rem/16);
    min-width: auto;
    font-family: var(--font-default-bold);
}
.main-navbar__dropdown-menu--medium {
    width: auto;
}
.main-navbar__icon-btn {
    padding: calc(10rem/16) calc(24rem/16) ;
    font-size: calc(22rem/16);
    height: calc(78rem/16);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    @media screen and (max-width: 1299px) and (min-width: 768px) {
        font-size: calc(18rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
        padding: calc(10rem/16) calc(10rem/16);
        height: calc(55rem/16);
        justify-content: center;
    }
    @media screen and (max-width: 350px) {
        padding: calc(10rem/16) calc(7rem/16);
    }
}
.main-navbar__icon-btn__icon {
    @media screen and (min-width: 768px) {
        border-left: 1px solid var(--color-dark-grey);
    }
}
.main-navbar__icon-btn--user .main-navbar__icon-btn__icon {
    @media screen and (max-width: 767px) {
        font-size: calc(21rem/16);
    }
}
.main-navbar__icon-btn--search {
    font-size: calc(20rem/16);
    @media screen and (max-width: 1299px) and (min-width: 768px) {
        font-size: calc(16rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(19rem/16);
    }
}
.main-navbar__icon-btn--search .main-navbar__icon-btn__icon {
    border-left: none;
}
.main-navbar__icon-btn__text {
    font-size: calc(10rem/16);
    font-family: var(--font-default-bold);
    margin-top: calc(4rem/16);
    @media screen and (min-width: 768px) {
        display: none;
    }
}
.main-navbar__icon-btn--cart-count {
    border-radius: 50%;
    height: calc(17rem/16);
    width: calc(17rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    line-height:1;
    color:#fff;
    font-size: calc(10rem/16);
    background-color: var(--color-primary);
    position: absolute;
    right:0;
    bottom: calc(13rem/16);
    @media screen and (max-width: 767px) {
        bottom: calc(4rem/16);
    }
}