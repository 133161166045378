.video-js .vjs-big-play-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gradient-primary);
    top: 50%;
    left: 50%;
    border: none;
    transform-origin: center;
    transform: translate(-50%, -50%) scale(1) ;
    transition: transform 400ms ease;
    font-size: calc(20rem/16);
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.5);
    @media screen and (max-width: 767px) {
        width:calc(60rem/16);
        height: calc(60rem/16);
    }
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: var(--icon-play);
    font-family: iconfont;
    font-size: calc(28rem/16);
    background: linear-gradient(46.77deg, #DA001C 0%, #9A0014 100%, #980013 100%);
    color: #fff;
    height: calc(70rem / 16);
    width: calc(70rem / 16);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: color 400ms ease;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.5);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem / 16);
        height: calc(50rem / 16);
        width: calc(50rem / 16);
    }
}
.video-js.vjs-has-started .vjs-big-play-button {
    opacity:0 !important;
}

/* hover states */
@media screen and (min-width: 768px) {
    .video-js:hover .vjs-big-play-button, .video-js:hover .vjs-big-play-button .vjs-icon-placeholder:before{
        transform-origin: center;
        opacity:1;
        transform: translate(-50%, -50%) scale(1.05);
        transition: transform 300ms ease;
    }
}

.video-js .vjs-big-play-button:focus, .video-js:hover .vjs-big-play-button{
    border: none;
    background-color: transparent;
}

.video-js .vjs-big-play-button:active{
    outline: none
}