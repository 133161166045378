:root {
    --container-xl-max-width: calc(1820rem/16);
    --container-max-width: calc(1480rem/16);
    --container-lg-max-width: calc(1260rem/16);
    --container-md-max-width: calc(1060rem/16);
    --container-narrow-max-width: calc(820rem/16)
}

.container.container {
    max-width: var(--container-max-width);
    width: 100%;
    @media screen and (min-width: 768px) {
        padding-left: calc(90rem/16);
        padding-right: calc(90rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-left: calc(15rem/16);
        padding-right: calc(15rem/16);
    }

}
.container.container-narrow {
    max-width: var(--container-narrow-max-width);
    width: 100%;
}
.container.container-md {
    max-width: var(--container-md-max-width);
    width: 100%;
}
.container.container-lg {
    max-width: var(--container-lg-max-width);
    width: 100%;
}
.container.container-xl {
    max-width: var(--container-xl-max-width);
    width: 100%;
}