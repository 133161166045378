.weather-item{
    box-shadow: var(--box-shadow-default);
    background-color: #FFF;
    padding: calc(20rem/16) calc(16rem/16) calc(16rem/16);

    @media screen and (min-width: 768px){
        padding: calc(50rem/16) calc(16rem/16);
    }

    @media screen and (min-width: 1400px){
        padding: calc(50rem/16) calc(90rem/16);
    }
}

.weather-item__content{
    padding: 0 calc(10rem/16);
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        padding: 0;
        margin-bottom: calc(40rem/16);
    }
}

.weather-item__icon{
    width: 3.5rem;
    margin: 0 auto 0.5rem;
    filter: invert(100%);

    @media screen and (min-width: 768px){
        width: 5.5rem;
        margin: 0 auto 0.7rem;
    }
}

.weather-item__degrees{
    font-size: 1.25rem;
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: 2rem;
    }
}