.press-gallery-item__content{
    padding: calc(17rem/16) calc(16rem/16) calc(14rem/16);
    box-shadow: var(--box-shadow-default);

    @media screen and (min-width: 768px) and (hover: hover){
        padding: calc(15rem/16) calc(15rem/16) calc(5rem/16);
        box-shadow: none;
    }
}

.press-gallery-item__button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: static;

    @media screen and (min-width: 768px) and (hover: hover){
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.2s;
    }
}

.press-gallery-item:hover .press-gallery-item__button {
    opacity: 1;
}

.press-gallery-item__copyright{
    font-size: var(--font-size-default-xs);
    color: #b9b9b9;
    line-height: 1.6;

    @media screen and (min-width: 768px){
        line-height: 1.9;
    }
}

.press-gallery-item__download-icon{
    font-size: 2rem;
}