.horizontal-teaser {
    box-shadow: var(--box-shadow-default);
}
.horizontal-teaser__body {
    background-color:#fff;
    padding: calc(50rem/16) calc(60rem/16);
    height: 100%;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.horizontal-teaser__title {
    color: var(--color-primary);
    margin-bottom: calc(20rem/16);
}
.horizontal-teaser__content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}
.horizontal-teaser__link {
    margin-top: calc(30rem/16);
}
.horizontal-teaser__date {
    font-size: calc(14rem/16);
    margin-bottom: calc(5rem/16);
}
.horizontal-teaser__media {
    transition: transform 0.4s ease;
}
.horizontal-teaser:hover .horizontal-teaser__media {
    transform: scale(1.1) translateX(-50%);
}