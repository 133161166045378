.custom-checkbox {
    position: relative;
    padding-left: calc(33rem/16);
}

.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
}

.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: none;
    background: #ffffff;
    box-shadow: 0 2px 7px rgba(0,0,0,.2);
    width: calc(18rem/16);
    height: calc(18rem/16);
    margin-top: 0;

    @media screen and (min-width: 768px){
        margin-top: 0.1em;
    }
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.7em;
    color: #FFF;
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}

.custom-checkbox__input:checked ~ .custom-checkbox__box{
    background-color: var(--color-primary);
    box-shadow: 0 2px 7px #da001c5c;
}

.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}
.custom-checkbox__text > a {
    color: var(--color-primary);
}

/* inline */
.form-check-inline .form-check-input {
    position: absolute; /* override bootstrap */
}