.text-img-slider-area {
    margin-top: calc(85rem/16);
    position: relative;
}
.text-img-slider-area__inner {
    background-color: #000;
    display: block;
    height: 100%;
    position: relative;
    color: #fff;
    padding-bottom: calc(180rem/16);
    padding-top: calc(85rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(60rem/16);
        padding-top: calc(40rem/16);
    }
}
.text-img-slider-area:before {
    content:'';
    position: absolute;
    top: calc(-5rem/16);
    width: 0;
    left:0;
    height: 0;
    border-style: solid;
    border-width: 0 0 calc(85rem/16) 100vw;
    border-color: transparent transparent #000 transparent;
    @media screen and (max-width: 767px) {
        top: 0;
        border-width: 0 0 calc(30rem/16) 100vw;
    }
}
.text-img-slider-area__inner:after {
    content:'';
    left:0 ;
    right:0;
    position: absolute;
    bottom: calc(0rem/16);
    width: 0;
    border-style: solid;
    border-width: 0 0 calc(85rem/16) 100vw;
    border-color: transparent transparent #fff transparent;
    @media screen and (max-width: 767px) {
        border-width: 0 0 calc(30rem/16) 100vw;
    }
}
.text-img-slider .slick-list {
    padding: 0 16%;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding: 0 7%;
    }
    @media screen and (max-width: 767px) {
        padding:0;
        overflow: visible;
    }
}
.text-img-slider__wrapper .slider__arrow-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    margin-right: calc(-35rem / 16);
    margin-left: calc(-35rem / 16);
    @media screen and (max-width: 1599px)   {
        margin-left:0;
        margin-right:0;
    }
    @media screen and (max-width: 767px) {
        position: relative;
        justify-content: center;
        transform: none;
    }
}
.text-img-slider__wrapper .slider__slide-count {
    position: absolute;
    bottom: calc(15rem / 16);
    right: 0;
    color: #fff;
}
.text-img-slider__wrapper {
    margin-top: calc(90rem/16);
    margin-bottom: calc(40rem/16);
    @media screen and (max-width: 1599px) {
        padding-left: calc(35rem/16);
        padding-right: calc(35rem/16);
        margin-top: calc(40rem/16);
        margin-bottom: calc(20rem/16);
    }
}

.text-img-slider .text-img-item__body {
    opacity: 0;
    transform: translateX(-50px) scale(0.5);
    transition: transform 0.4s cubic-bezier(0.6, 0, 0.5, 1), opacity 0.4s cubic-bezier(0.6, 0, 0.5, 1);
}
.text-img-slider .text-img-item__img {
    transition: transform 0.5s cubic-bezier(0.6, 0, 0.5, 1);
    transform: translateX(120%) scale(0.6);
    @media screen and (max-width: 767px) {
        transform: translateX(120%) translateY(100%) scale(0.6);
    }
}
.text-img-slider .text-img-item__img:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 2;
    top:0;
    left:0;
    opacity:1;
    transition: opacity 0.3s ease;
    background-color: rgba(0,0,0,0.6);
}
.text-img-slider .slick-current .text-img-item__img:before {
    opacity:0;
}
.text-img-slider .slick-current .text-img-item__img{
    transform: translateX(0) scale(1);
    transition: transform 0.3s cubic-bezier(0.6, 0, 0.5, 1);
    @media screen and (max-width: 767px) {
        transform-origin: bottom;
        transform: translateX(0%) translateY(0%) scale(0.9);
    }

}
.text-img-slider .slick-current ~ .slick-slide .text-img-item__img  {
    transform: translateX(-20%) scale(0.6);
    z-index: -1;
    @media screen and (max-width: 767px) {
        transform: translateX(-20%) translateY(100%) scale(0.6);
    }
}
.slick-current .text-img-item__body {
    opacity: 1;
    transform: translateX(0%) scale(1);
}

.text-img-slider.slider {
    @media screen and (max-width: 767px) {
        margin-bottom: 1.25rem;
    }
}

.text-img-slider-area.editmode .pimcore_wysiwyg,
.text-img-slider-area.editmode .pimcore_editable_input {
    background-color: black;
}