.info-area__content{
    background: var(--gradient-primary);
    width: 100%;
    cursor: pointer;
    padding: calc(35rem/16) calc(25rem/16) calc(25rem/16);
    color: #FFF;
    line-height: 1.5;

    @media screen and (min-width: 768px){
        line-height: 1.3;
        flex-grow: 1;
        flex-basis: 40%;
        padding: calc(25rem/16) calc(30rem/16);
    }

    @media screen and (min-width: 1200px){
        line-height: 1.5;
        padding: calc(52rem/16) calc(65rem/16);
    }
}
.info-area__img-wrapper{
    width: 100%;
    z-index: 1;

    @media screen and (min-width: 768px){
        flex-basis: 60%;
        flex-grow: 1;
    }
}
.info-area__wrapper{
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}
.info-area__img{
    transition: transform 0.3s;
}
.info-area__wrapper:hover .info-area__img{
    transform: scale(1.02);
}
.info-area__title {
    margin-bottom: calc(25rem/16);
}
.info-area__item {
    display: flex;
    margin-bottom: calc(15rem/16);
}
.info-area__item address {
    margin-bottom:0;
}
.info-area__item:last-of-type {
    margin-bottom: 0;
}
.info-area__item__icon {
    font-size: calc(22rem/16);
    width: calc(40rem/16);
}