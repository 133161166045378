.form-control.has-value{
    padding-top: calc(16rem/16);
    padding-bottom: 0;

    @media screen and (min-width: 768px) {
        padding-top: calc(18rem/16);
    }
}
.form-group__search:before {
    content: var(--icon-search);
    font-family: iconfont;
    color: var(--color-primary);
    speak: none;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: calc(15rem/16);
    font-size: calc(26rem/16);
    pointer-events: none;
}

input.is-invalid, select.is-invalid, textarea.is-invalid {
    border: 1px solid var(--color-primary);
}

input.is-valid, select.is-valid, textarea.is-valid {
    border: 1px solid var(--color-success);
}

/*select*/

.select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select__input-wrapper:before {
    font-family: "iconfont";
    content: var(--icon-arrow-down);
    color: var(--color-text-default);
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(28rem / 16);
    top: 50%;
    right: calc(11rem / 16);
    transform: translateY(-50%);
    z-index: 10;
    background-color: white;

    @media screen and (min-width: 768px) {
        right: calc(11rem / 16);
    }
}

/*textarea*/
textarea.form-control{
    padding-top: calc(12rem/16);
    min-height: calc(140rem/16);

    @media screen and (min-width: 768px) {
        padding-top: calc(16rem/16);
    }
}

textarea.form-control.has-value{
    padding-top: calc(24rem/16);

    @media screen and (min-width: 768px) {
        padding-top: calc(30rem/16);
    }
}

.file-input__btn {
    position: absolute;
    right: calc(10rem/16);
    top: 50%;
    transform: translateY(-50%);
    font-size: calc(35rem/16);
    color: var(--color-primary);
    display: flex;
}
.file-input__text {
    display: flex;
    align-items:center;
}
.file-input__btn:before {
    font-family: iconfont;
    content:var(--icon-upload);
}
.file-input__btn.has-file:before {
    content: var(--icon-trash);
    font-size: calc(22rem/16);
    padding-right: calc(5rem/16);
}

.form-group__search .form-control{
    padding-right: 3rem;
}

.jobs-overview__category{
    min-width: calc(168rem/16);
}