.text-teaser {
    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
    padding: calc(24rem/16) calc(16rem/16) calc(16rem/16);
    display: block;

    @media screen and (min-width: 1200px) {
        padding: calc(40rem/16) calc(30rem/16) calc(30rem/16);
    }
}
.text-teaser__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}
.text-teaser__sub-title {
    margin-bottom: calc(10rem/16);
}
.text-teaser__title {
    margin-bottom: calc(30rem/16);

    @media screen and (max-width: 1200px){
        font-size: 1.2rem;
    }
}
.text-teaser__list {
    font-size: calc(15rem/16);
    margin-bottom: calc(20rem/16);
}