.teaser-slider .slick-list{
    position: relative;
}

.teaser-slider__item{
    position: relative;
    color: #000;
}

.teaser-slider .slick-list::before {
    content: "";
    position: absolute;
    top: 0;
    transform: rotate(-4deg) translateY(-6.625rem) translateX(-5vw);
    box-shadow: 10px 0 20px rgba(0, 0, 0, 0.3);
    width: 110vw;
    height: 9rem;
    background: #FFF;
    z-index: 2;
    pointer-events: none;

    @media screen and (min-width: 768px) {
        transform: rotate(-2.8deg) translateY(-6rem) translateX(-5vw);
        box-shadow: 10px 0 40px rgba(0, 0, 0, 0.3);
    }
}

.teaser-slider .slick-list::after {
    content: "";
    position: absolute;
    bottom: 0;
    transform: rotate(-4deg) translateY(7.325rem) translateX(-5vw);
    box-shadow: 10px 0 20px rgba(0, 0, 0, 0.3);
    width: 110vw;
    height: 9rem;
    background: #FFF;
    z-index: 2;
    pointer-events: none;

    @media screen and (min-width: 768px){
        transform: rotate(-2.8deg) translateY(5.3rem) translateX(-5vw);
        box-shadow: 10px 0 40px rgba(0, 0, 0, 0.3);
    }
}

.teaser-slider__body{
    position: absolute;
    left: calc(20rem/16);
    right: calc(20rem/16);
    top: 0;
    bottom: 0;
    margin-top: calc(110rem/16);
    z-index: 2;
    text-align: center;


    @media screen and (min-width: 768px){
        margin-top: 0;
        transform: translateY(-44%);
        left: 10%;
        top: 50%;
        right: auto;
        bottom: auto;
        text-align: left;
    }

    @media screen and (min-width: 1200px){
        left: 16%;
    }
}

.teaser-slider__content{
    display: none;
    font-size: calc(13rem/16);

    @media screen and (min-width: 768px){
        display: block;
        width: calc(530rem/16);
        margin-top: calc(14rem/16);
    }

    @media screen and (min-width: 1200px){
        font-size: var(--font-size-default);
        left: 16%;
        margin-top: calc(40rem/16);
    }
}

.teaser-slider__title{
    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(32rem/16);
        margin-bottom: 0;
    }
}

.teaser-slider__img-wrapper{
    position: relative;
}

.teaser-slider__img-wrapper::before{
    content: "";
    position: absolute;
    background: linear-gradient(180deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;

    @media screen and (min-width: 768px){
        background: linear-gradient(90deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.6) 50%, rgba(255,255,255,0) 100%);
    }
}



.teaser-slider__embed::before{
    padding-top: calc(calc(5%/3)*100);

    @media screen and (min-width: 768px){
        padding-top: calc(calc(9%/21)*100);
    }
}

.teaser-slider__button{
    @media screen and (max-width: 767px){
        width: 100%;
        margin-top: calc(30rem/16);
    }
}

.teaser-slider__additional-infos{
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: calc(20rem/16);

    @media screen and (min-width: 768px){
        padding-right: 13%;
        padding-bottom: 3%;
    }
}

.teaser-slider__additional-infos .slider__arrow{
    width: 3.75rem;
    height: 3.75rem;
    font-size: 3.75rem;
}