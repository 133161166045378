.content-block + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(50rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(100rem/16);
    }
}
.content-block + .content-block--small, .content-block--small + .content-block,
.content-block + .pimcore_area_content > .content-block--small,
.pimcore_area_content + .pimcore_area_content > .content-block--small,
.content-block--small + .pimcore_area_content > .content-block--small
{
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(50rem/16);
    }
}