.content-teaser__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 calc(18rem / 16) calc(12rem / 16);
    z-index: 4;

    @media screen and (min-width: 768px) {
        padding: 0 calc(30rem / 16) calc(24rem / 16);
    }
}

.content-teaser__title {
    margin-bottom: 0;
    word-wrap: break-word;

    @media screen and (max-width: 1199px) {
        font-size: 1.5rem;
    }

    @media screen and (max-width: 767px) {
        font-size: 1.5rem;
    }
}

.content-teaser__subtitle {
    margin-bottom: calc(4rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(8rem / 16);
    }
}

.content-teaser__img {
    transition: transform 0.3s;
}

.content-teaser__item:hover .content-teaser__img {
    transform: scale(1.02);
}

.js-content-teaser-slider {
    @media screen and (max-width: 767px) {
        margin: 0;
    }
}

.content-teaser__arrow.slick-prev {
    transform: none;

    @media screen and (min-width: 1199px) {
        transform: translateX(-50%);
    }
}

.content-teaser__arrow.slick-next {
    position: relative;
    transform: none;

    @media screen and (min-width: 1199px) {
        position: absolute;
        right: 0;
        transform: translateX(50%);
    }
}

.content-teaser__arrow-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    transform: translateY(-30%);
    line-height: 1;
    pointer-events: none;

    @media screen and (min-width: 768px) {
        justify-content: space-between;
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-52%);
    }
}

.content-teaser__arrow-wrapper .slider__arrow{
    pointer-events: initial;
}

.content-teaser__badge {
    top: 0;
}

.content-teaser__img-wrapper {
    position: relative;
}

.content-teaser__img-wrapper::after {
    background: linear-gradient(0deg, rgba(0, 0, 0, .6), transparent 50%);
    z-index: 2;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.embed-responsive-wideLandscape::before{
    padding-top: 100%;

    @media screen and (min-width: 768px){
        padding-top: calc(calc(100%/2) - 0.75rem);
    }
}

.slider__arrow.content-teaser__arrow.slick-disabled, .slider__arrow.content-teaser__icon.slick-disabled{
    pointer-events: initial;
    cursor: initial;
}