.customer-experience__icon {
    color: var(--color-primary);
    font-size: calc(40rem/16);
    display: flex;
    @media screen and (max-width: 767px) {
        font-size: calc(30rem/16);
    }
}
.customer-experience__form__inner {
    margin-bottom: calc(50rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(30rem/16);
    }
}