.status{
    width: calc(27rem/16);
    height: calc(27rem/16);
    font-size: 12px;
    border-radius: 50%;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: calc(10rem/16);

    @media screen and (min-width: 768px){
        width: calc(30rem/16);
        height: calc(30rem/16);
        font-size: var(--font-size-default);
    }
}
.status--small {
    width: calc(15rem/16);
    height: calc(15rem/16);
    font-size: calc(10rem/16);
    @media screen and (min-width: 768px){
        width: calc(20rem/16);
        height: calc(20rem/16);
        font-size: calc(12rem/16);
    }
}
.status--success{
    background-color: var(--color-success);
    box-shadow: 0 0 10px rgba(6, 184, 41, 0.4);
}

.status--error{
    background-color: var(--color-danger);
    box-shadow: 0 0 10px rgba(220, 53, 69, 0.3);
}

.status--info{
    background-color: var(--color-info);
    box-shadow: 0 0 10px rgba(255, 193, 7, 0.5);
}

.status--black{
    background-color: #000;
    box-shadow: 0 0 10px rgba(49, 49, 49, 0.4);
}

.status--red{
    background-color: var(--color-danger);
    box-shadow: 0 0 10px rgba(220, 53, 69, 0.3);
}

.status--yellow{
    background-color: var(--color-info);
    box-shadow: 0 0 10px rgba(255, 193, 7, 0.5);
}
.status--blue {
    background-color: var(--color-info-blue);
    box-shadow: 0 0 10px rgba(111, 235, 255, 0.5);
}