.text-badge {
    position: absolute;
    right: 0;
    z-index: 2;
    background: linear-gradient(90deg, #DA001C 35%, #9A0014 100%, #980013 100%);
    padding: 0 calc(8rem/16);
    height: calc(33rem/16);
    font-family: var(--font-default-bold);
    font-size: .8125rem;
    color: #FFF;
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        padding: 0 calc(13rem/16);
        height: calc(44rem/16);
    }
}

.text-badge:before{
    content: "";
    position: absolute;
    top: 0;
    left: calc(-18rem/16);
    width: 0;
    height: 0;
    z-index: 999;
    border-style: solid;
    border-top: 0 solid transparent;
    border-right: 0 solid transparent;
    border-bottom: calc(33rem / 16) solid var(--color-primary);
    border-left: calc(18rem / 16) solid transparent;

    @media screen and (min-width: 768px) {
        left: calc(-25rem/16);
        border-bottom: calc(44rem/16) solid var(--color-primary);
        border-left: calc(25rem/16) solid transparent;
    }
}

