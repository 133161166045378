@media (hover: none) {
    .simple-nav__title-link {
        display: block;
    }
    .simple-nav__item.has-children > .simple-nav__item-content--link {
        display: none;
    }
    .simple-nav__item.has-no-children > .simple-nav__item-content--toggle {
        display: none;
    }
    .simple-nav__item-content--toggle.simple-nav__item-content--level-0 {
        display: flex;
    }
}
/* samsung fix */
@media screen and (max-width: 767px) {
    .simple-nav__title-link {
        display: block;
    }
    .simple-nav__item.has-children > .simple-nav__item-content--link {
        display: none;
    }
    .simple-nav__item.has-no-children > .simple-nav__item-content--toggle {
        display: none;
    }
    .simple-nav__item-content--toggle.simple-nav__item-content--level-0 {
        display: flex;
    }
}
.simple-nav__item-content--toggle, .simple-nav__title-link {
    display: none;
}
.simple-nav {
    pointer-events: auto;
}
