.facility-item{
    box-shadow: var(--box-shadow-default);
}

.facility-item:not(:last-of-type){
    margin-bottom: calc(20rem/16);
}

.facility-header{
    cursor: pointer;
    height: calc(70rem/16);

    @media screen and (min-width: 768px){
        height: calc(90rem/16);
    }
}

.facility-header__badge{
    position: relative;
    width: calc(45rem/16);
    height: 100%;
    color: #FFF;
    font-size: 1.8rem;
    background-color: var(--color-primary);
    background: linear-gradient(270deg,#980013,#da001c);
    margin-right: calc(35rem/16);

    @media screen and (min-width: 768px){
        font-size: 2.5rem;
        width: calc(70rem/16);
        margin-right: calc(55rem/16);
    }
}

.facility-header__badge::after{
    content: "";
    position: absolute;
    top: 0;
    right: calc(-35rem/16);
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 0 solid transparent;
    border-right: 0 solid transparent;
    border-bottom: calc(70rem / 16) solid transparent;
    border-left: calc(35rem / 16) solid var(--color-triangle-primary-dark);

    @media screen and (min-width: 768px){
        font-size: 2.5rem;
        right: calc(-54.75rem/16);
        border-bottom: calc(90rem / 16) solid transparent;
        border-left: calc(55rem / 16) solid var(--color-triangle-primary-dark);
    }
    @media screen and (min-width: 992px){
        right: calc(-55rem/16);
    }
}

.facility-header__type-icon{
    display: block;
    position: absolute;
    top: 48%;
    right: 3px;
    transform: translateY(-50%);
    font-size: 1.4rem;
    padding-top: 0.3rem;

    @media screen and (min-width: 768px){
        font-size: 1.8rem;
    }
}

.facility-header__type-icon.icon-snowshoe,
.facility-header__type-icon.icon-avalanche-training{
    font-size: 0.65rem;
    @media screen and (min-width: 768px){
        font-size: 1rem;
    }
}

.facility-header__info{
    flex-grow: 1;
}

.facility-header__title{
    @media screen and (max-width: 767px){
        font-size: 1rem;
    }
}

.facility-header__arrow{
    font-size: 1.8rem;
    margin-right: calc(8rem/16);
    transition: transform 0.3s;
    transform: rotate(0deg);

    @media screen and (min-width: 768px){
        font-size: 2.5rem;
        margin-right: calc(30rem/16);
    }
}

.facility-header:not(.collapsed) .facility-header__arrow{
    transform: rotate(180deg);
}

.facility-safety{
    padding: calc(20rem/16) calc(16rem/16) calc(16rem/16) ;
    line-height: 1.8;

    @media screen and (min-width: 768px){
        padding: calc(30rem/16) calc(38rem/16);
    }
}

.facility-table__thead{
    background-color: var(--color-dark-grey);
    font-size: calc(16rem/16);
    height: calc(54rem/16);
}

.facility-table{
    margin-bottom: 0;
}

.table.facility-table tr:last-of-type td{
    border-bottom: none;
}

.facility-table tr {
    height: calc(72rem/16);
    line-height: 1;
}

.facility-table td, .facility-table th{
    @media screen and (max-width: 768px){
        padding: 0 1rem;
    }
}

.facility-table__number{
    background-color: #000;
    width: calc(28rem/16);
    height: calc(28rem/16);
    font-size: 13px;
    color: #FFF;
    border-radius: 5px;
    font-family: var(--font-default-bold);
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px){
        width: calc(30rem/16);
        height: calc(30rem/16);
        font-size: var(--font-size-default);
    }
}
