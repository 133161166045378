.hero-portal {
    width: 100%;
    height: calc(100vh + 30px);
    position: relative;
    @media screen and (max-width: 767px) {
        height: calc(520rem/16);
        overflow: hidden;
    }
}
.hero-portal__decorator {
    position: absolute;
    width: 100%;
    bottom: -1px;
    pointer-events: none;
    z-index: 2;
    display: block;
    @media screen and (max-width: 767px) {
        height: calc(170rem/16);
        z-index: 1;
    }
}
.hero-portal__scroll-btn  {
    position: absolute;
    bottom: calc(70rem/16);
    transform: translateY(50%) translateX(-50%);
    width: calc(160rem/16);
    height: calc(160rem/16);
    background-size: 300% 100%;
    transition: all 0.2s ease-in-out;
    background-image: linear-gradient(to right, #DA001C, #9A0014, #b10017, #ec001e);
    color:#fff;
    font-size: calc(15rem/16);
    letter-spacing: calc(2rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 3;
    text-align: center;
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    left: 50%;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    animation: portalPulse 2s infinite;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.hero-portal__scroll-btn:hover {
    color:#fff;
    background-position: 100%;
}
.hero-portal__content-wrapper {
    width: 100%;
    position: absolute;
    z-index: 2;
    right:0;
    left:0;
    color:#fff;
    height: calc(100% - var(--main-nav-height));
    margin: 0 auto;
    top: var(--main-nav-height);
    max-width: 80%;
    display: flex;
    justify-content: center;
    align-items:center;
    text-align: center;
    flex-direction: column;
}
.hero-portal__title {
    text-transform: uppercase;
    text-shadow: 0 4px 5px rgba(0,0,0,0.4);
    font-size: calc(80rem/16);
    line-height: calc(101/80);
    margin-bottom:0;
    @media screen and (max-width: 767px) {
        font-size: calc(35rem/16);
    }
}
.hero-portal__sub-title {
    font-size: calc(25rem/16);
    line-height: calc(31/25);
    margin-bottom: calc(10rem/16);
    text-shadow: 0 2px 7px rgba(0,0,0,0.6);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
        margin-bottom: calc(5rem/16);
    }
}
.hero-portal__btn {
    margin-top: calc(50rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(30rem/16);
    }
}
.hero-portal__video-btn {
    margin: calc(100rem/16) auto 0;
    display: block;
    color: #fff;
    font-size: calc(60rem/16);
    text-shadow: 0 4px 5px rgba(0,0,0,.4);
    background: transparent;
    border: none;
    @media screen and (max-width: 767px) {
        margin: calc(40rem/16) auto 0;
    }
}
.hero-portal__bg-video {
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
    top:0;
    left:0;
}
.hero__bg-video__video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    position: absolute;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
@keyframes portalPulse {
    0% {
        box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }
    70% {
        box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    }
}
.hero-summer-portal__canvas, .hero-portal__canvas {
    @media screen and (max-width: 767px) {
        display:none;
    }
}