.weather-forecast__item+.weather-forecast__item{
    margin-top: calc(16rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(50rem/16);
    }
}

.weather-forecast__wrapper{
    box-shadow: var(--box-shadow-default);
    background-color: #FFF;
}

.weather-forecast__badge{
    position: relative;
    min-width: 80%;
    display: inline-flex;
    height: calc(50rem/16);
    color: #FFF;
    font-size: 1.8rem;
    background-color: var(--color-primary);
    background: linear-gradient(270deg,#980013,#da001c);
    margin-right: calc(35rem/16);
    align-items: center;
    padding-left: calc(16rem/16);
    padding-right: calc(16rem/16);
    line-height: 1;

    @media screen and (min-width: 768px){
        font-size: 2.5rem;
        min-width: 35%;
        margin-right: calc(55rem/16);
        padding-left: calc(40rem/16);
        padding-right: calc(22rem/16);
        height: calc(70rem/16);
    }
}

.weather-forecast__badge::after{
    content: "";
    position: absolute;
    top: 0;
    right: calc(-35rem/16);
    width: 0;
    height: 0;
    border-color: transparent #980013;
    border-style: solid;
    border-width: calc(50rem/16) 0 0 calc(35rem/16);

    @media screen and (min-width: 768px){
        font-size: 2.5rem;
        right: calc(-55rem/16);
        border-width: calc(70rem/16) 0 0 calc(55rem/16);
    }
}

.weather-forecast__badge-title{
    @media screen and (max-width: 767px) {
        font-size: calc(20rem / 16);
    }
}

.weather-forecast__day-wrapper{
    padding: calc(25rem/16) 0;

    @media screen and (min-width: 768px){
        padding: calc(30rem/16) 0;
    }
}


.weather-forecast__day-wrapper+.weather-forecast__day-wrapper{
    border-top: 1px solid var(--color-dark-grey);
    border-left: 0;

    @media screen and (min-width: 768px){
        border-top: 0;
        border-left: 1px solid var(--color-dark-grey);
    }
}

.weather-forecast__day{
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-size: calc(28rem/16);
    line-height: calc(28/16);
}

.weather-forecast__data{
    text-align: left;
    padding: calc(16rem/16) calc(30rem/16) 0;

    @media screen and (min-width: 768px){
        padding: calc(22rem/16) calc(30rem/16) 0;
    }

    @media screen and (min-width: 1200px){
        padding: calc(22rem/16) calc(30rem/16) 0;
    }
}

.weather-forecast__icon{
    font-size: 3.5rem;
    margin-bottom: 0.5rem;

    @media screen and (min-width: 768px){
        font-size: 5rem;
    }
}

.weather-forecast__degrees{
    font-size: 1.25rem;
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: calc(35rem/16);
    }
}