@media screen and (max-width: 767px) {
    .mobile-overlay {
        position: fixed;
        opacity:1;
        width: 85%;
        height: auto;
        background-color:#fff;
        bottom:0;
        left:0;
        margin: 0 auto;
        box-shadow: var(--box-shadow-default);
        right:0;
        z-index: 99;
    }
    .mobile-overlay__body {
        text-align: center;
        padding: calc(30rem/16) calc(20rem/16) calc(30rem/16);
    }
    .mobile-overlay__title {
        color: var(--color-primary);
        font-size: calc(22rem/16);
        font-family: var(--font-default-bold);
    }
    .mobile-overlay__close {
        background-color: var(--color-primary);
        color:#fff;
        border: none;
        box-shadow: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(50rem/16);
        height: calc(50rem/16);
        position: absolute;
        right:0;
        top:0;
        font-size: calc(30rem/16);
    }
    .mobile-overlay__image {
        max-width: 50%;
        margin: 0 auto;
        margin-top: -26%;
    }
}