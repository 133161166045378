.media-area__embed {
    overflow: visible;
}
.media-area__embed:after {
    content:'';
    box-shadow: 0 0 60px 0 rgba(0,0,0,0.4);
    height: 50%;
    width: 100%;
    position: absolute;
    right:0;
    border-radius: 30px;
    top:50%;
    z-index: -1;
    transform: translateY(-50%);
}

.media-area__cookie-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: calc(24rem/16);
    z-index: 1;
    row-gap: calc(12rem/16);
}