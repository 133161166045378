.hero {
    width: 100%;
    height: calc(100vh - 78px);
    position: relative;
    @media screen and (max-width: 767px) {
        height: calc(520rem / 16);
        overflow: hidden;
    }
}
.hero:before {
    background: linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
    content:'';
    opacity:0.8;
    position: absolute;
    height:100%;
    width: calc(170rem/16);
    z-index: 1;
    @media screen and (max-width: 767px) {
        opacity:0.4;
        width: 100%;
        background: linear-gradient(180.41deg, rgba(0,0,0,0.15) 0%, rgba(0,0,0,0.4) 66.44%, rgba(0,0,0,0) 100%);
    }
}
.hero__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-image: var(--image-src-xs) !important;
    @media screen and (min-width: 768px) {
        height: 100%;
        background-image: var(--image-src) !important;
    }
    @media screen and (min-width: 1400px) {
        background-attachment: fixed;
    }
}
.hero__content-wrapper {
    background: linear-gradient(90.78deg,#da001c,#9a0014 100%,#980013 0);
    color: #fff;
    position: absolute;
    z-index: 2;
    bottom:0;
    right:0;
    min-width: 50%;
    width: auto;
    max-width: 80%;
    height: calc(190rem/16);
    padding: calc(40rem/16) calc(30rem/16);
    display: flex;
    align-items:center;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
        height: calc(86rem/16);
    }
}
.hero__content-wrapper:before {
    content: "";
    position: absolute;
    left: calc(-80rem/16);
    width: 0;
    bottom: calc(0rem/16);
    height: 0;
    z-index: 999;
    border-style: solid;
    border-left: calc(80rem / 16) solid transparent;
    border-bottom: calc(86rem / 16) solid var(--color-primary);
    border-right: 0 solid transparent;
    border-top: 0 solid transparent;

    @media screen and (min-width: 768px) {
        border-left: calc(120rem / 16) solid transparent;
        border-bottom: calc(190rem / 16) solid var(--color-primary);
        left: calc(-119rem/16);
    }
}
.hero__sub-title {
    margin-bottom: calc(15rem / 16);
}
.hero__title {
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
        font-size: calc(22rem/16);
        line-height: 1.1;
    }
}
