.icon-in-text {
    vertical-align: -.12em;
}
.icon-in-text--lg {
    font-size: 1.8em;
}
.icon-in-text--md {
    font-size: 1.2em;
}
.icon-in-text--sm {
    font-size: .8em;
}

.icon-in-text.icon-in-text--lg{
    vertical-align: -0.3em;
}

.btn-primary-gradient .icon-in-text--lg{
    margin-right: 1rem;
}

.btn-icon-in-text--lg{
    padding-top: 0.775rem;
    padding-bottom: 0.775rem;
}
/* Icons specific placement */

/*.icon-in-text.icon-arrow-right {
    vertical-align: -.2em;
}*/