.main-footer {
    background-color:var(--color-primary);
    color:#fff;
    padding: calc(50rem/16) 0 calc(30rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) 0 calc(60rem/16);
    }
}
.main-footer__title {
    font-family: var(--font-default-bold);
    font-size: calc(22rem/16);
    line-height: calc(27/22);
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        font-size: calc(18rem/16);
    }
    @media screen and (max-width: 767px) {
        display: flex;
        font-size: calc(16rem/16);
        flex-direction: row;
        padding: calc(20rem/16);
        margin-bottom:0;
        justify-content: space-between;
    }
}
.main-footer__content {
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        font-size: calc(14rem/16);
    }
    @media screen and (max-width: 767px) {
        padding: 0 calc(20rem / 16) calc(20rem / 16);
    }
}
.main-footer__title:after {
    @media screen and (max-width: 767px) {
        content: '';
        display: block;
        width: 100%;
        height: calc(1rem / 16);
        background-color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
.main-footer__collapse-icon {
    transition: transform 0.2s ease;
}
.main-footer__title:not(.collapsed) .main-footer__collapse-icon {
    transform: rotate(45deg);
}
.main-footer__text {
    line-height: calc(30/16);
    margin-bottom: calc(20rem/16);
}
.main-footer__link {
    line-height: calc(30/16);
}
.main-footer__link:hover {
    text-decoration: underline;
}
.main-footer__link-icon {
    font-size: calc(18rem/16);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        font-size: calc(14rem/16);
    }
}
.main-footer__social-links {
    margin-top: calc(60rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(34rem/16);
        display: flex;
        justify-content: space-around;
    }
}
.main-footer__social-link {
    width: calc(42rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: calc(42rem/16);
    background-color:#fff;
    font-size: calc(22rem/16);
    color: var(--color-primary);
    border: 1px solid #fff;
    transition: color 0.2s ease, background-color 0.2s ease;
}
.main-footer__social-link:hover {
    background-color: var(--color-primary);
    color:#fff;
}
.main-footer .icon-sos {
    scale: .75;
    transform-origin: left center;
}