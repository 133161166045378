.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}
.wysiwyg ul {
    padding-left: calc(20rem/16);
    list-style: none;
}
.wysiwyg ul li {
    position: relative;
    padding-left: calc(20rem/16);
    padding-bottom: calc(10rem/16);
}
.wysiwyg ul li:before {
    position: absolute;
    content: "";
    left: 0;
    top: calc(6rem/16);
    width: calc(7rem/16);
    height: calc(7rem/16);
    transform: skew(-30deg);
    background-color: var(--color-primary);
}
.wysiwyg ul li > ul li:before {
    border-radius: 50%;
    transform: none;
}

/*tables*/
.wysiwyg-area__button{
    margin-top: calc(40rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
    }
}

.wysiwyg thead {
    background-color: var(--color-dark-grey);
    font-size: calc(18rem/16);
}

.wysiwyg th{
    transform: translateY(2px);
    height: calc(75rem/16);
}

.wysiwyg td{
    transform: translateY(2px);
    height: calc(60rem/16);
}

.wysiwyg .table-heading--small{
    font-size: calc(16rem/16);
    font-family: var(--font-default);
}

.wysiwyg .table-heading--small strong {
    display: block;
}

.wysiwyg--centered {
    text-align: center;
}