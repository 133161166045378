/* slider with default gutter */
.slider-with-gutter--default {
    overflow: hidden;
}

.slider-with-gutter--default .slick-list {
    margin: 0 calc(-2rem / 16);

    @media screen and (min-width: 1400px) {
        margin: 0 calc(-10rem / 16);
    }
}

.slider-with-gutter--default .slider-item {
    padding: 0 calc(2em / 16);

    @media screen and (min-width: 1400px) {
        padding: 0 calc(10rem / 16);
    }
}

/* slider with small gutter */
.slider-with-gutter--small {
    overflow: hidden;
}

.slider-with-gutter--small .slick-list {
    margin: 0 calc(-2rem / 16);

    @media screen and (min-width: 1400px) {
        margin: 0 calc(-5rem / 16);
    }
}

.slider-with-gutter--small .slider-item {
    padding: 0 calc(2em / 16);

    @media screen and (min-width: 1400px) {
        padding: 0 calc(5rem / 16);
    }
}