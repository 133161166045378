/* COOKIE BAR */

.cookie-bar{
    background-color: #000;
    z-index: 9999;
}

.cookie-bar .cookie-bar__settings-btn{
    background: transparent;
    border: 1px solid #FFF;
    padding-top: calc(13.4rem/16);
    padding-bottom: calc(13.4rem/16);
}
.cookie-bar .cookie-bar__settings-btn:hover{
    background-color: #FFF;
    color: #000;
}

.cookie-bar .cookie-bar__accept {
    background-size: 300% 100%;
    transition: all 0.2s ease-in-out;
    background-image: linear-gradient(to right, #DA001C, #9A0014, #b10017, #ec001e);
    color: #fff;
    border: none;
    box-shadow: 0 5px 15px rgba(112,0,14,.3);
}
.cookie-bar .cookie-bar__accept:hover {
    color:#fff;
    background-position: 100%;
}

.cookie-bar__buttons .cookie-bar__btn {
    @media screen and (max-width: 767px){
        margin: 0.75rem auto 0;
    }
}

.cookie-bar .cookie-bar__detail-link{
    @media screen and (max-width: 767px){
        font-size: var(--font-size-default-xs);
    }
}

.cookie-bar .cookie-bar__left{
    @media screen and (max-width: 767px){
        text-align: center;
    }
}

/* COOKIE MODAL */
.cookie-modal .modal-dialog {
    @media (min-width: 576px){
        max-width: 600px;
        margin: 6rem auto;
    }
}

.cookie-modal .modal-body{
    padding: 2rem 1rem;

    @media screen and (min-width: 768px) {
        padding: 2rem 3rem 5rem 3rem;
    }
}

.cookie-modal .modal-header{
    padding: 0;
}

.cookie-modal .modal-header .close {
    padding: 0.5rem 0.9rem .5rem;
    font-size: 2rem;

    @media screen and (min-width: 768px) {
        padding: 0.8rem 1.2rem .5rem;
        font-size: 2.2rem;
    }
}

.cookie-modal .cookie-modal__title{
    padding: 2.5rem 0 0 1rem;

    @media screen and (min-width: 768px) {
        padding: 3.5rem 0 0 3rem;
    }
}

.cookie-modal .modal-footer {
    padding: 0 0 2.5rem 0;
    justify-content: center;

    @media screen and (min-width: 768px) {
        padding: 0 3.5rem 3.5rem 0;
        justify-content: flex-end;
    }
}

.cookie-modal .cookie-modal__switch{
    width: 50px;
    height: 26px;
    @media screen and (min-width: 768px){
        width: 54.8px;
        height: 30px;
    }
}

.cookie-modal .cookie-modal__switch-toggle:before {
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;

    @media screen and (min-width: 768px){
        height: 22px;
        width: 22px;
        left: 5px;
        bottom: 4px;
    }
}

.cookie-modal input:checked+.cookie-modal__switch-toggle:before {
    transform: translateX(22px);
}

.cookie-modal .cookie-modal__label {
    font-size: 1rem;

    @media screen and (min-width: 768px) {
        font-size: 1.3rem;
    }
}

