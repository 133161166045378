.footer__bottom {
    background-color: var(--color-dark-grey);
    padding: calc(28rem/16) 0;
    color: var(--color-darkest-grey);
    font-size: calc(14rem/16);
    @media screen and (max-width: 767px) {
        text-align: center;
        padding: calc(28rem/16) 0 calc(80rem/16);
    }
}
.footer__bottom__link-list {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 767px) {
        flex-flow: row wrap;
        margin-top: calc(15rem/16);
        margin-bottom: calc(15rem/16);
    }
}
.footer__bottom__link-list-item {
    padding-left: calc(18rem/16);
    padding-right: calc(18rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(8rem/16);
    }
}
.footer__bottom__link-list-item:hover {
    color: var(--color-primary);
}
.footer__bottom__logo {
    width: calc(151rem/16);
}