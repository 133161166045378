.live-infos__wrapper {
    position: fixed;
    left:calc(20rem/16) !important;
    width: calc(60rem/16);
    top: 55% !important; /* important needed for midnight js */
    z-index: 9;
    transform: translateY(-50%);
    text-align:center;
    @media screen and (max-height: 750px) and (min-width: 768px) {
        top: calc(var(--main-nav-height) + 50px) !important;
        transform: none;
    }
    @media screen and (max-width: 767px) {
        left: 0 !important;
        width: 100%;
        top: inherit !important;
        bottom:0;
        padding-top: calc(5rem/16);
        transform: none;
        background-color:#fff;
        color: #000;
        display: flex;
        align-items:center;
    }
}
.live-infos__wrapper--has-warning {
    @media screen and (max-height: 750px) and (min-width: 768px) {
        padding-top: calc(50rem/16);
    }
}
.live-infos__item-text {
    display: block;
    font-size: calc(13rem/16);
    font-family:var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(10rem/16);
    }
}
.live-infos__wrapper--has-warning .live-infos__item-icon {
    font-size: calc(12rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(9rem/16);
    }
}
.live-infos__item-icon {
    font-size: calc(35rem / 16);
    @media screen and (max-height: 750px) and (min-width: 768px) {
        font-size: calc(28rem / 16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(28rem/16);
    }
}
.live-infos__wrapper--has-warning .live-infos__item-icon {
    font-size: calc(28rem / 16);
    @media screen and (max-height: 750px) and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(22rem/16);
    }
}
.live-infos__item + .live-infos__item {
    margin-top: calc(20rem/16);
    @media screen and (max-height: 750px) and (min-width: 768px) {
        margin-top: calc(15rem/16);
    }
    @media screen and (max-width: 767px) {
        margin-top: 0;
    }
}
.live-infos__wrapper--has-warning .live-infos__item + .live-infos__item {
    margin-top: calc(20rem/16);
    @media screen and (max-height: 750px) and (min-width: 768px) {
        margin-top: calc(13rem/16);
    }
    @media screen and (max-width: 767px) {
        margin-top: 0;
    }
}
.live-infos__list {
    @media screen and (max-width: 767px) {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }
}