.highlight-teaser {
    position: relative;
    cursor: pointer;
}
.highlight-teaser__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: calc(8rem/16);
    text-align: center;
    letter-spacing: calc(3rem/16);
    color: #fff;
    text-transform: uppercase;

    @media screen and (min-width: 767px){
        padding: calc(30rem/16) calc(20rem/16);
    }
}
.highlight-teaser__content:before {
    content:'';
    position: absolute;
    pointer-events: none;
    width: 100%;
    left:0;
    top:0;
    z-index: 1;
    height: 40%;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
    display: block;
    will-change: background;
    transition: background 0.3s ease;
}
.highlight-teaser:before{
    content:'';
    pointer-events: none;
    box-shadow: 0 0 60px 0 rgba(0,0,0,0.8);
    height: 50%;
    width: 100%;
    position: absolute;
    right:0;
    border-radius: 30px;
    top:50%;
    z-index: -1;
    transform: translateY(-50%);
    opacity:0.5;
    will-change:opacity;
    transition: opacity 0.4s cubic-bezier(0.6, 0, 0.5, 1);
}
.highlight-teaser:after {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    background-color: #fff;
    opacity:0;
    will-change:opacity;
    transition: opacity 0.4s cubic-bezier(0.6, 0, 0.5, 1);
}
.highlight-teaser__title {
    transition: all 0.4s cubic-bezier(0.6, 0, 0.5, 1);
    left:0;
    right:0;
    top: calc(30rem/16);
    display: inline-flex;
    z-index: 9;
    position: relative;
    text-shadow: 0 1px 8px rgba(0,0,0,0.5);
    @media screen and (max-width: 767px) {
        z-index: 8;
        font-size: calc(14rem/16);
        letter-spacing: 1.3px;
    }
}
@media screen and (min-width: 768px) {

    .highlight-teaser__wrapper:hover .highlight-teaser:after {
        opacity: 0.4;
    }
    .highlight-teaser__wrapper .highlight-teaser:hover:after {
        opacity:0;
    }
    .highlight-teaser:hover:before {
        opacity:1;
    }
    .highlight-teaser:hover {
        position: relative;
    }
    .highlight-teaser:hover .highlight-teaser__content:before {
        z-index: 1;
        background: linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.5) 100%);
        height:100%;
    }
    .highlight-teaser:hover .highlight-teaser__title {
        z-index: 9;
        line-height:1;
        transform: scale(2) translateY(calc(100rem/16));
    }
    .highlight-teaser__media {
        transition: transform 0.5s ease;
    }
    .highlight-teaser:hover .highlight-teaser__media {
        transform: scale(1.1);
    }
}