.partner-teaser{
    background-color: #FFF;
    box-shadow:  var(--box-shadow-default);
    padding: calc(30rem/16) calc(60rem/16);

    @media screen and (max-width: 1199px) and (min-width: 768px){
        padding: calc(30rem/16) calc(30rem/16);
    }
}

.partner-teaser__content{
    margin-top: calc(30rem/16);
}

.partner-teaser__title{
    color: var(--color-primary);
    margin: 0 0 calc(20rem/16);
}

.partner-teaser__address{
    margin: calc(20rem/16) 0 calc(15rem/16);
}

.partner-teaser__link{
    margin-top: calc(30rem/16);
}


