.loading-spinner {
    margin-left: auto;
    margin-right: auto;
    width: calc(300rem/16);
    text-align: center;
}
.loading-spinner path, .loading-spinner polygon {
    stroke-dasharray: 2338.5;
    stroke-dashoffset: 2338.5;
    animation: stroke 1s linear infinite;
}

@keyframes stroke {
    to { stroke-dashoffset: 0;}
}

.loading-spinner .cloud {
    animation: cloud 1s linear infinite;
}
@keyframes cloud {
    from {opacity: 0; transform: translateX(0);}
    to { opacity: 1; transform: translateX(20px);}
}