.report-winter{
    box-shadow: 0 5px 30px rgba(0,0,0,0.1);
    background-color: #FFF;
    padding: calc(30rem/16) calc(16rem/16);

     @media screen and (min-width: 768px) {
         padding: calc(60rem/16) calc(90rem/16);
     }
}

.report-winter__icon-skier{
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.report-winter__icon-gondola{
    font-size: 2.2rem;
    margin-bottom: 0.7rem;
    margin-top: 0.1rem;
}