.accommodation-teaser__wrapper{
    margin-bottom: calc(30rem/16);

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
/*
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) and (max-width: 767px){
        display: block !important;
    }*/
}

.accommodation-teaser__body{
    background: var(--gradient-primary);
    width: 100%;
    padding: calc(35rem/16) calc(25rem/16) calc(25rem/16);
    color: #FFF;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    @media screen and (min-width: 768px){
        line-height: 1.3;
        flex-grow: 1;
        flex-basis: 40%;
        padding: calc(25rem/16) calc(30rem/16);
        min-height: calc(241rem/16);
    }

    @media screen and (min-width: 1200px){
        line-height: 1.5;
        padding: calc(52rem/16) calc(65rem/16);
        min-height: calc(341rem/16);
    }
}

.accommodation-teaser__img-wrapper{
    width: 100%;
    z-index: 1;

    @media screen and (min-width: 768px){
        flex-basis: 60%;
        max-width: 60%;
        flex-grow: 1;
    }
}

.accommodation-teaser__title{
    @media screen and (min-width: 768px) and (max-width: 1200px){
        font-size: calc(28rem/16);
    }
}

.accommodation-teaser__subtitle {
    margin-bottom: calc(4rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(8rem / 16);
    }
}

.accommodation-teaser__content{
    margin: calc(11rem/16) 0 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    width: 100%;

    @media screen and (min-width: 768px) {
        margin: calc(15rem/16) 0 0;
    }

    @media screen and (min-width: 1200px) {
        margin: calc(18rem/16) 0 0;
    }
}

.accommodation-teaser__button{
    margin: calc(20rem/16) 0 0;

    @media screen and (min-width: 768px) {
        margin: calc(25rem/16) 0 0 ;
    }

    @media screen and (min-width: 1200px) {
        margin: calc(27rem/16) 0 0;
    }
}

.accommodation-teaser__button:hover{
    background-color: #FFF;
    color: var(--color-primary);
}

/* slider */

.slider.accommodation-teaser__slider{
    margin: 0;
    background-color: #FFF;
}

.slider .accommodation-slider__arrow.slick-arrow {
    padding: 0;
}

.slider .slick-next.accommodation-slider__arrow:before{
    content: var(--icon-arrow-down);
    transform: rotate(270deg);
    font-size: 2.5rem;

    @media screen and (min-width: 768px) {
        font-size: 4rem;
    }
}

.slider .slick-prev.accommodation-slider__arrow:before{
    content: var(--icon-arrow-down);
    transform: rotate(90deg);
    font-size: 2.5rem;

    @media screen and (min-width: 768px) {
       font-size: 4rem;
    }
}

.slider .accommodation-slider__arrow:focus{
    outline: none;
}
