@media screen and (min-width: 768px) {
    .simple-nav__title-link--level-2,
    .simple-nav__item-content--level-2 {
        padding: .3rem 0;
    }
    .simple-nav__item--level-2 {
        margin-bottom: calc(10rem/16);
    }
    .simple-nav--level-2 {
        margin-top: calc(10rem/16);
    }
    .simple-nav__title-link.simple-nav__title-link--level-2 {
        display:none;
    }
    .simple-nav__title-link--level-2:hover,
    .simple-nav__title-link--level-2:focus,
    .simple-nav__title-link--level-2:active,
    .simple-nav__item-content--level-2:hover,
    .simple-nav__item-content--level-2:focus,
    .simple-nav__item-content--level-2:active {
        color: var(--color-primary);
    }
}
