:root {
    --color-primary: #DA001C;
    --color-secondary: #6c757d;
    --color-text-default: #2D343E;
    --color-text-muted: #868E96;

    --color-default: #2D343E;
    --color-white: #FFF;
    --color-light-grey: #f5f5f5;
    --color-dark-grey: #dfdfdf;
    --color-darkest-grey:#6B6C6C;
    --color-dark: #343A40;

    --color-success: #06B829;
    --color-info: #F1C439;
    --color-warning: #F1C439;
    --color-info-blue: #17A2B8;
    --color-danger: #C71118;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #9a0014;
    --color-secondary-dark: #424D56;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #3A88C4;
    --color-secondary-light: #6E8090;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --color-triangle-primary-dark: #980013;
    --gradient-primary: linear-gradient(35.78deg, #DA001C 0%, #9A0014 100%, #980013 100%);

    --font-default: "dax-regular", sans-serif;
    --font-default-bold: "dax-bold", sans-serif;

    --font-size-default: 1rem;
    --font-size-default-xs: calc(14rem/16);

    --main-nav-height: 118px;
    --box-shadow-default: 0 0 20px rgba(0,0,0,0.1);
}
