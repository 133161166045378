.accordion-item {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: calc(20rem / 16);
}

.accordion-item__header {
    height: calc(80rem / 16);
    @media screen and (max-width: 767px) {
        height: calc(50rem / 16);
    }
}

.accordion-item__toggle {
    width: calc(80rem / 16);
    position: relative;
    background: linear-gradient(90deg, #da001c 35%, #9a0014 100%, #980013 0);
    height: calc(80rem / 16);
    color: #fff;
    font-size: calc(40rem / 16);
    display: flex;
    padding-right: calc(10rem / 16);
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        font-size: calc(32rem / 16);
        height: calc(50rem / 16);
        width: calc(50rem / 16);
        padding-right: calc(5rem / 16);

    }
}

.accordion-item__toggle:before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(-50rem / 16);
    width: 0;
    height: 0;
    z-index: 1;
    border-left: calc(50rem / 16) solid transparent;
    border-bottom: calc(80rem / 16) solid var(--color-primary);
    border-right: 0 solid transparent;
    border-top: 0 solid transparent;
    @media screen and (max-width: 767px) {
        left: calc(-25rem / 16);
        border-bottom: calc(50rem / 16) solid var(--color-primary);
        border-left: calc(25rem / 16) solid transparent;
    }
}

.accordion-item__title {
    padding: 0 calc(30rem / 16);
    margin-bottom: 0;
    line-height: 1;
    @media screen and (max-width: 767px) {
        padding: calc(0rem/16) calc(10rem/16);
        font-size: calc(16rem/16);
    }
}

.accordion-item__body {
    padding: calc(30rem / 16);
    border-top: 1px solid var(--color-dark-grey);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}

.accordion-item__toggle-icon {
    transition: transform 0.3s ease;
}

.accordion-item__header-link:not(.collapsed) .accordion-item__toggle-icon {
    transform: rotate(180deg);
}
