.slider.logo-slider{
    @media screen and (max-width: 767px){
        margin-bottom: 0;
    }
}

.slider__arrow.logo-slider__arrow{
    height: calc(50rem/16);
    width: calc(50rem/16);
    font-size: calc(35rem/16);
    pointer-events: initial;

    @media screen and (min-width: 768px){
        height: calc(55rem/16);
        width: calc(55rem/16);
        font-size: 3rem;
    }
}

.logo-slider__arrow.slick-prev {
    transform: none;

    @media screen and (min-width: 768px) {
        transform: translateX(-115%);
    }
}

.logo-slider__arrow.slick-next {
    position: relative;
    transform: none;

    @media screen and (min-width: 768px) {
        position: absolute;
        right: 0;
        transform: translateX(115%);
    }
}

.logo-slider__arrow-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    transform: translateY(-65%);
    line-height: 1;
    pointer-events: none;

    @media screen and (min-width: 768px) {
        justify-content: space-between;
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-52%);
    }
}