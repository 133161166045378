.main-content__body {
    padding-top: calc(60rem/16);
    padding-bottom: calc(24rem/16);

    @media screen and (max-width: 767px) {
        padding-top: calc(30rem/16);
    }
}
.main-content {
    overflow:hidden;
    padding-top: calc(78rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(55rem/16);
    }
}
.main-content--no-spacing {
    @media screen and (min-width: 768px) {
        padding-top:0;
    }
}
.main-content__body--has-bg {
    background-size:auto;
    background-position: 50% 5%;
    background-repeat: no-repeat
}