.img-text-teaser{
    box-shadow:  var(--box-shadow-default);
    background-color: #FFF;
}

.img-text-teaser:hover .img-text-teaser__img{
    transform: scale(1.02);
}

.img-text-teaser__img{
    transition: transform 0.3s;
}

.img-text-teaser__title{
    color: var(--color-primary);
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(19rem/16);
    }
}

.img-text-teaser__body{
    padding: calc(34rem/16) calc(40rem/16);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    @media screen and (min-width: 768px){
        padding: calc(20rem/16) calc(24rem/16);
    }

    @media screen and (min-width: 1200px){
        padding: calc(34rem/16) calc(40rem/16);
    }
}

.img-text-teaser__content{
    line-height: calc(24/16);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin-bottom: calc(38rem/16);
}
