.embed-responsive-cover{
    height: 100%;
}
.embed-responsive-cover .embed-responsive-item{
    width: auto;
    min-height: 100%;
    min-width: 100%;
    left: 50%;
    transform: translateX(-50%);
}
.embed-responsive__webcam:before {
    padding-top: 61.25%;
}