.dropdown-toggle:after {
    content: var(--icon-arrow-down);
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    border: none;
    font-size: calc(20rem/16);
    top: 50%;
    text-rendering: auto;
    margin: 0;
    position: absolute;
    transform: translateY(-50%);
}
.dropdown-toggle.dropdown-toggle--hide-arrow:after {
    display: none;
}