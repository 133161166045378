.text-img-item__body {
    background: linear-gradient(46.77deg, #DA001C 0%, #9A0014 100%, #980013 100%);
    padding: calc(35rem/16) calc(50rem/16) calc(40rem/16);
    color:#fff;
    text-align: center;

    @media screen and (max-width: 1399px) and (min-width: 768px) {
        padding: calc(25rem/16) calc(40rem/16);
    }

    @media screen and (max-width: 767px) {
        padding: calc(25rem/16);
    }
}
.text-img-item__icon {
    font-size: calc(30rem/16);
    margin-bottom: calc(18rem/16);
}
.text-img-item__sub-title {
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin-bottom: calc(15rem/16);
}
.text-img-item__title {
    margin-bottom: calc(30rem/16);
    @media screen and (max-width: 1399px) {
        margin-bottom: calc(15rem/16);
        font-size: calc(20rem/16);
    }
}
.text-img-item__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    margin-bottom: calc(40rem/16);
    @media screen and (max-width: 1399px) {
        margin-bottom: calc(15rem/16);
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}