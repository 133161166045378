.select--custom {
    font-family: var(--font-default-bold);
}
.select--custom .form-control {
    font-size: calc(26rem/16);
    color: var(--color-primary);
    box-shadow: none;
    margin-right: calc(20rem/16);
    @media screen and (max-width: 1199px) and (min-width: 768px){
        font-size: calc(22rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}
.select--custom .select__input-wrapper:before {
    font-size: calc(30rem/16);
    color: var(--color-primary);
    right:0;
}
.select--custom option {
    font-size: calc(16rem/16);
    color: var(--color-text-default);
    font-family: var(--font-default);
    padding: calc(5rem/16);
}