.wysiwyg-area__content--centered {
    text-align: center;
}
.wysiwyg-area--has-bg {
    padding-top: calc(60rem/16);
    background-size: auto;
    background-repeat: no-repeat;
    background-position: top center;
    @media screen and (max-width: 767px)  {
        background-size: contain;
        padding-top: 0;
    }
}