.simple-gallery {
    overflow: visible;
}
.simple-gallery__wrapper {
    margin-top: calc(100rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(40rem/16);
    }
}
.simple-gallery .slick-list {
    overflow: visible;
}
.simple-gallery .slick-slide {
    opacity:1 !important;
}
.simple-gallery__item {
    position: relative;
    opacity:1;
    transform: rotate(0deg) scale(1) translateY(0%);
    transition: opacity 0.2s, transform 0.2s ease-in-out;
    transform-origin: top;
}
.simple-gallery__item:before {
    content:'';
    position: absolute;
    width: 100%;
    left:0;
    top:0;
    z-index: 1;
    height: 100%;
    transition: opacity 0.2s ease;
    background-color: rgba(255,255,255,0.7);
}
.slick-current .simple-gallery__item:before {
    opacity:0;
}


.simple-gallery .slick-current + .slick-slide .simple-gallery__item {
    opacity:1;
    z-index: 1;
    transform: rotate(-12deg) scale(.8) translateY(2%);
    transition: opacity 0.2s, transform 0.2s ease-in-out;

}
.simple-gallery .slick-slide {
    z-index: 997 !important;
}
.simple-gallery .slick-current + .slick-slide {
    z-index: 998 !important;
}
.simple-gallery .slick-current.slick-slide {
    z-index: 999 !important;
}
.simple-gallery .slick-current + .slick-slide + .slick-slide .simple-gallery__item {
    opacity:1;
    z-index: 0;
    transition: opacity 0.3s 0.01s, transform 0.2s ease-in-out;
    transform: rotate(-24deg) scale(0.6) translateY(0%);
}
.simple-gallery .slick-current .simple-gallery__item {
    opacity:1;
    z-index: 2;
    transform: rotate(0deg) scale(1) translateY(0%);
}
.simple-gallery__description-text {
    opacity: 0;
    transition: opacity 0.2s ease;
}
.simple-gallery .slick-current .simple-gallery__description-text {
    opacity:1;
}