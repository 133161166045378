.link-icon--red, .link-icon--white{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--color-primary);
    font-family: var(--font-default-bold);
}

.link-icon--white{
    color: #FFF !important;
}

.link-icon__icon{
    font-size: 2rem;
    margin-right: calc(4rem/16);
}

.contact-link, .contact-link--white {
    display: flex;
    align-items: center;
    transition: color 0.2s, transform 0.3s;
    word-break: break-all;
}

.contact-link:hover{
    color: var(--color-primary);
}

.contact-link--white:hover{
    transform: translateX(2px);
}
