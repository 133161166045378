.simple-nav--level-1 {
    @media screen and (min-width: 768px) {
        background-color: #fff;
        color: var(--color-default);
        z-index: 10;
        position: fixed;
        top: calc(77rem / 16);
        width: 100%;
        left: 0;
        visibility: hidden;
        opacity: 0;
        box-shadow: 0 .25rem 1rem 0 rgba(0, 0, 0, .2);
        min-width: 20rem;
        padding: calc(50rem / 16) 26% 0;
        margin: 0 auto;
        min-height: calc(350rem / 16);
        transition: visibility 0.001ms 300ms, opacity 300ms ease;
    }
    @media screen and (min-width: 768px) and (max-width: 1599px) {
        padding: calc(50rem / 16) 15% 0;
    }
}
@media screen and (min-width: 768px) {
    .simple-nav__item--level-1, .simple-nav__item-content--level-1 {
        margin-bottom: calc(10rem/16);
    }
    .simple-nav--level-1:after {
        content: "";
        position: absolute;
        bottom: calc(2rem/16);
        margin-bottom: -5.3125rem;
        width: 0;
        left:0;
        border-color: #fff transparent transparent;
        border-style: solid;
        border-width: 5.3125rem 100vw 0 0;
    }
    .simple-nav__item.is-open > .simple-nav--level-1 {
        opacity: 1;
        transition: visibility 300ms 0.001ms, opacity 300ms ease;
        box-shadow: 100px 100px 50px 40px rgba(0, 0, 0, 0.1);
        visibility: visible;
    }

    .simple-nav__title--level-1 {
        font-family: var(--font-default-bold);
    }
    .simple-nav__title-link--level-1,
    .simple-nav__item-content--level-1 {
        padding: .3rem 0;
        font-family: var(--font-default-bold);
    }
    .simple-nav__title-link--level-1:hover,
    .simple-nav__title-link--level-1:focus,
    .simple-nav__title-link--level-1:active,
    .simple-nav__item-content--level-1:hover,
    .simple-nav__item-content--level-1:focus,
    .simple-nav__item-content--level-1:active {
        color: var(--color-primary);
    }
}
