.info-slider-area {
    position: relative;
}
.info-slider-area__container {
    z-index: 2;
    position: relative;
}
.info-slider-area:before {
    content: '';
    top:0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.8) 48.33%, #FFFFFF 100%);
    z-index: 1;
}
.info-slider {
    padding-top: calc(30rem/16);
    overflow: hidden;
    @media screen and (max-width: 767px) {
        margin-bottom: calc(10rem/16);
    }
    @media screen and (max-width: 1199px) {
        overflow:visible;
    }
}
.info-slider .slick-list {
    padding: 0 26%;
    overflow: visible;
    @media screen and (max-width: 1199px) {
        padding: 0;
    }
}
.info-slider-item {
    position: relative;
}
.info-slider-item__embed:before {
    padding-top: calc( 419 / 640 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: 128.46875%;
    }
}
.info-slider-item__body {
    text-align: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    padding: calc(50rem / 16) calc(90rem / 16);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding: calc(40rem / 16);
    }
    @media screen and (max-width: 767px) {
        padding: calc(20rem / 16);
    }
}
.info-slider-item__body:before {
     content:'';
     display: block;
     position: absolute;
     width: 100%;
     left: 0;
     top:0;
     height: 100%;
     background: rgba(0,0,0,0.6);
     opacity: 1;
     transition: opacity 0.1s ease;
 }
.info-slider-item__body:after {
    content:'';
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    top:0;
    height: 100%;
    background: linear-gradient(0deg, rgba(218, 0, 28, 0.85) 0%, rgba(218, 0, 28, 0.8) 100%);
    opacity: 0;
    z-index: 0;
    transition: opacity 0.1s ease;
}
.info-slider-item__body-inner {
    z-index: 1;
    position: relative;
    transition: transform 0.3s ease;
    transform: translateY(30%);
}
.info-slider-item__icon {
    font-size: calc(70rem/16);
    margin-bottom: calc(10rem/16);
    @media screen and (max-width: 1199px) {
        font-size: calc(50rem/16);
    }
}
.info-slider-item__title {
    margin-bottom: calc(36rem/16);
    @media screen and (max-width: 1199px) {
        margin-bottom: calc(15rem/16);
        font-size: calc(22rem/16);
    }
}
.info-slider-item__content {
    opacity: 0;
    transition: opacity 0.3s ease;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    margin-bottom: calc(30rem/16);
    @media screen and (max-width: 1399px) {
        -webkit-line-clamp: 3;
        margin-bottom: calc(15rem/16);
    }
}
.info-slider-item__link {
    opacity: 0;
    transition: opacity 0.3s ease;
}
.info-slider .slick-current .info-slider-item__body:after {
    opacity:1;
}
.info-slider .slick-current .info-slider-item:before {
    content:'';
    box-shadow: 0 0 60px 0 rgba(0,0,0,0.8);
    height: 50%;
    width: 100%;
    position: absolute;
    right:0;
    border-radius: calc(30rem/16);
    top:50%;
    transform: translateY(-50%);
}
.info-slider-item {
    transform: scale(0.7) translateX(33%);
    transition: transform 0.7s cubic-bezier(0.6, 0, 0.5, 1);
    transform-origin: center;
}
.info-slider .slick-current .info-slider-item {
    transform: scale(1);
    transition: transform 0.5s cubic-bezier(0.7, 0, 0.4, 1);
}
.info-slider .slick-current .info-slider-item__body:before {
    opacity:0;
}
.info-slider .slick-current .info-slider-item__body-inner {
    transform: translateY(0);
}
.info-slider .slick-current .info-slider-item__content, .slick-current .info-slider-item__link {
    opacity: 1;
    transform: translateY(0);
}
.info-slider .slick-current ~ .slick-slide .info-slider-item {
    transform: scale(0.7) translateX(-33%);
    z-index: -1;
    transition: transform 0.7s cubic-bezier(0.6, 0, 0.5, 1);
}
.info-slider-item__badge {
    opacity:0;
    transform: scale(0);
    transition: transform 0.4s cubic-bezier(0.6, 0, 0.5, 1), opacity 0.2s ease;
}
.info-slider .slick-current .info-slider-item__badge {
    opacity:1;
    transform: scale(1);
}
.info-slider__wrapper .slider__arrow-wrapper {
    position: absolute;
    top: 53%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    margin-right: calc(-35rem / 16);
    margin-left: calc(-35rem / 16);
    @media screen and (max-width: 1599px) {
        margin-left: 0;
        margin-right: 0;
    }
    @media screen and (max-width: 767px) {
        position: relative;
        justify-content: center;
        transform: none;
    }
}
.info-slider__wrapper {
    @media screen and (max-width: 1599px) {
        padding-left: calc(35rem/16);
        padding-right: calc(35rem/16);
    }
}
.info-slider__wrapper .slider__slide-count {
    position: absolute;
    bottom: calc(15rem/16);
    right:0;
    width: 24%;
    z-index: -1;
    @media screen and (max-width: 1599px) {
        width: auto;
        text-align:right;
        padding-right: calc(35rem/16);
    }
    @media screen and (max-width: 767px) {
        bottom: calc(5rem/16);
    }
}