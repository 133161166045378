.horizontal-gallery__item {
    transform: rotate(0deg) scale(1) translateY(0%);
    transition: opacity 0.2s, transform 0.2s ease-in-out;
    transform-origin: top;
    position: relative;
}
.horizontal-gallery__item:before {
    content:'';
    position: absolute;
    width: 100%;
    left:0;
    top:0;
    z-index: 1;
    height: 100%;
    transition: opacity 0.3s cubic-bezier(0.6, 0, 0.5, 1);
    background-color: rgba(255,255,255,0.7);
}

.slick-current .horizontal-gallery__item:before {
    opacity:0;
    transition: opacity 0.3s cubic-bezier(0.6, 0, 0.5, 1);
}
.horizontal-gallery__description-text {
    opacity: 0;
    transition: opacity 0.2s ease;
}
.horizontal-gallery .slick-current .horizontal-gallery__description-text {
    opacity:1;
}

.horizontal-gallery__thumbs {
    position: absolute !important;
    left:0;
    width: 100%;
    z-index: -1;
    top:50%;
    transform: translateY(-50%);
}

.horizontal-gallery__thumb-item {
    padding: calc(2rem/16);
}
.horizontal-gallery__thumbs .slick-list {
    overflow: visible !important;
    padding: 0 calc(270rem/16) !important;
    margin-left: calc(300rem/16) !important;
}
.horizontal-gallery__wrapper .slider__additional-infos {
    @media screen and (max-width: 767px) {
        bottom: calc(14rem/16);
    }
}
