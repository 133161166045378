.modal-close {
    font-size: calc(35rem / 16);
    color: var(--color-text-default);
    opacity: 1;
}

.modal-body {
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}

.modal__search-btn {
    font-size: calc(20rem / 16);
    color: #fff;
    height: calc(50rem / 16);
    padding-left: calc(10rem / 16);
    padding-right: calc(16rem/16);
    background: linear-gradient(90deg, #980013, #da001c);
    border: 0;

    @media screen and (min-width: 768px) {
        padding-right: calc(21rem/16);
        font-size: calc(25rem / 16);
        height: calc(60rem / 16);
    }
}

.modal__search-btn:hover {
    color: #fff;
}

.modal__search-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(-25rem / 16);
    width: 0;
    height: 0;
    z-index: 999;
    border-top: 0 solid transparent;
    border-right: 0 solid transparent;
    border-bottom: calc(50rem / 16) solid var(--color-triangle-primary-dark);
    border-left: calc(25rem / 16) solid transparent;

    @media screen and (min-width: 768px) {
        left: calc(-30rem / 16);
        border-bottom: calc(60rem / 16) solid var(--color-triangle-primary-dark);
        border-left: calc(30rem / 16) solid transparent;
    }
}

.modal-header .close.modal__search-close{
    font-size: 1.4rem;
    padding-top: 0.8rem;

    @media screen and (min-width: 768px){
        font-size: 1.8rem;
        padding-top: 0.8rem;
    }
}