.hotspots {
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 1199px) {
        overflow-x:scroll;
        margin-right: calc(-15rem/16);
    }
}
.hotspots__container {
    @media screen and (max-width: 1199px) {
        width: calc(900rem/16);
    }
}
.hotspots__wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hotspots__hotspot{
    padding: calc(2rem/16) calc(5rem/16);
    position: relative;
    background-color: #fff;
    border: 1px solid var(--color-dark);
    display: flex;
    justify-content: center;
    font-size: calc(14rem/16);
    align-items: center;
    color: #313131;
    @media screen and (max-width: 1199px) {
        font-size: calc(11rem/16);
    }
}
.hotspots__hotspot:before {
    content:'';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    z-index: 9;
}
.hotspots__hotspot--bottom:before {
    bottom: calc(-8rem/16);
    border-width: calc(8rem/16) calc(8rem/16) 0 calc(8rem/16);
    border-color: var(--color-dark) transparent transparent transparent;
    left: 50%;
    transform: translateX(-50%);
}
.hotspots__hotspot--top:before {
    top: calc(-8rem/16);
    border-width: 0 calc(8rem/16) calc(8rem/16) calc(8rem/16);
    border-color: transparent transparent var(--color-dark) transparent;
    left: 50%;
    transform: translateX(-50%);
}
.hotspots__hotspot--left:before {
    left: calc(-8rem/16);
    border-width: calc(8rem/16) calc(8rem/16) calc(8rem/16) 0;
    border-color: transparent var(--color-dark) transparent transparent;
    top: 50%;
    transform: translateY(-50%);
}
.hotspots__hotspot--right:before {
    right: calc(-8rem/16);
    border-width: calc(8rem/16) 0 calc(8rem/16) calc(8rem/16);
    border-color: transparent transparent transparent var(--color-dark) ;
    top: 50%;
    transform: translateY(-50%);
}
.hotspots__hotspot:hover {
    animation: pulse 2s infinite;
}
.hotspots__hotspot-inner {
    display: flex;
    justify-content: center;
    align-items: center;
}
.hotspots__detail.is-open{
    display: block;
}
.hotspots__hotspot-icon {
    color: var(--color-default);
}
.hotspots__swipe-overlay__icon {
    display: none;
}
@media screen and (max-width: 1199px) {
    .hotspots__swipe-overlay {
        position: absolute;
        top:0;
        left:0;
        width: calc(100vw - 15px);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,0.4);
        color:#fff;
        z-index: 5;
        font-size: calc(50rem/16);
    }
    .hotspots__swipe-overlay__icon {
        display: block;
        transform: translateX(-50%);
        animation: swipe 1s infinite alternate;
    }
}
@keyframes swipe {
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(50%);
    }
}