@media screen and (max-width: 767px) {
    .simple-nav {
        display: none;
    }
    .simple-nav__item.is-open > .simple-nav {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color:#fff;
        top: 0;
        right:0;
        bottom:0;
        left:0;
        overflow-y: hidden;
        z-index: 9;
        padding: calc(15rem/16) calc(20rem/16) calc(55rem/16);
        margin-bottom:0;
        display: flex;
        flex-direction: column;
    }
    .simple-nav__list {
        height: 100%;
    }
    .simple-nav__scrolling-container {
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        height:  100%;
    }

    .simple-nav__list--level-2 {
        height: calc(100% - 60px);
    }

    .simple-nav {
        padding-left: 1.5rem;
        margin-bottom: .5rem;
    }
    .simple-nav__item {
        display: block;
        width: 100%;
    }
    .simple-nav__title-link,
    .simple-nav__item-content {
        display: block;
        width: 100%;
        font-size: calc(16rem/16);
        padding: calc(20rem/16) calc(10rem/16);
        border-bottom: calc(1rem/16) solid var(--color-dark-grey);
    }
    .simple-nav__item-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    /* level 0 */
    .simple-nav--level-0 {
        display: block;
        position: fixed;
        top: var(--main-navbar-height--xs);
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        color: var(--color-default);
        margin: 0;
        padding: 1.5rem;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        visibility: hidden;
        transform: translateX(-100%);
        transition: 120ms ease;
    }
    .simple-nav--level-0.is-open {
        visibility: visible;
        transform: translateX(0);
    }
    .is-open > .simple-nav__item-content--level-0 {
        color: var(--color-primary);
    }

    .simple-nav__back {
        left:0;
        position: absolute;
        bottom:0;
        width: 100%;
        display: block;
    }
    .simple-nav__footer {
        left:0;
        position: absolute;
        bottom:0;
        width: 100%;
        background-color: var(--color-dark);
        color:#fff;
        display: block;
    }

    .simple-nav__item.is-open>.simple-nav > .simple-nav__list--level-2 > .simple-nav__back{
        z-index: 99;
    }

    .simple-nav__item.is-open>.simple-nav > .simple-nav__list--level-1 > .simple-nav__back{
        z-index: 9;
    }

        /* Level 1 */
    .simple-nav--level-1 {}
}

.simple-nav__collapse-icon {
    @media screen and (min-width: 768px) {
        display: none;
    }

    @media screen and (max-width: 767px) {
        display: block;
        font-size: calc(20rem/16);
        transition: transform 120ms ease-out;
        transform-origin: center center;
        transform: none;
    }
}
.is-open > .simple-nav__item-content > .simple-nav__collapse-icon {
    transform: rotate(180deg);
}

