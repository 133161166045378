.newsletter-slide {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: calc(520rem/16);
    position: relative;
    overflow: hidden;
    color:#fff;
}
.newsletter-slide:after {
    content:'';
    position: absolute;
    bottom:0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 calc(85rem/16) 100vw;
    border-color: transparent transparent var(--color-primary) transparent;
    @media screen and (max-width: 767px) {
        border-width: 0 0 calc(20rem/16) 100vw;
    }
}
.newsletter-slide__inner {
    background: linear-gradient(88.29deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%, rgba(0,0,0,0) 100%);
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: calc(100rem/16);
    padding-bottom: calc(100rem/16);
}
.newsletter-slide__title {
    margin-bottom: calc(22rem/16);
}
.newsletter-slide__content {
    margin-bottom: calc(40rem/16);
}
.newsletter-slide__decorator {
    position: absolute;
    top:0;
    z-index: 2;
    width: 100%;

    @media screen and (max-width: 767px) {
        transform: scale(4);
        transform-origin: top;
    }
}