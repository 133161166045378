.round-badge {
    width: calc(157rem/16);
    height: calc(157rem/16);
    background-color:#fff;
    color: var(--color-primary);
    font-size: calc(18rem/16);
    font-family: var(--font-default-bold);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(20rem/16);
    border-radius: 50%;
    text-align: center;
    position: absolute;
    right: -5%;
    top: -5%;
    z-index: 9;
    box-shadow: 0 5px 15px 0 rgba(56,2,9,0.4);
    line-height: 1.1;
    @media screen and (max-width: 767px) {
        width: calc(100rem/16);
        height: calc(100rem/16);
        padding: calc(10rem/16);
        font-size: calc(12rem/16);
        top: -10%;
        right: -10%;
    }
}
.round-badge:before {
    content:'';
    border-radius: 50%;
    width: calc(140rem/16);
    height: calc(140rem/16);
    pointer-events: none;
    border: 2px solid var(--color-primary);
    z-index: 1;
    display: block;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 767px) {
        width: calc(90rem/16);
        height: calc(90rem/16);
    }
}