.slider__arrow {
    background: linear-gradient(46.77deg, #DA001C 0%, #9A0014 100%, #980013 100%);
    color: #fff;
    height: calc(70rem / 16);
    width: calc(70rem / 16);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: calc(60rem / 16);

    @media screen and (max-width: 767px) {
        width: calc(50rem / 16);
        height: calc(50rem / 16);
        font-size: calc(35rem / 16);
    }
}

.slider__arrow.slick-disabled, .slider__arrow.content-teaser__icon.slick-disabled {
    background: linear-gradient(46.77deg, #f35e70 0%, #c45f6c 100%, #c95a6e 100%);
    pointer-events: none;
}

.slider__wrapper {
    position: relative;
}

.slider__arrow + .slider__arrow {
    margin-left: calc(4rem / 16);
}

.slider__slide-count {
    color: var(--color-primary);
    font-size: calc(16rem / 16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem / 16);
    }
}

.slider__slide-count--current {
    font-size: calc(25rem / 16);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(18rem / 16);
    }
}

.slider__additional-infos {
    padding-right: calc(40rem / 16);
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: calc(300rem / 16);
    @media screen and (max-width: 767px) {
        max-width: calc(160rem / 16);
        padding-right: 0;
        transform: translateY(-50%);
    }
}

.slider__description-text {
    margin-top: calc(10rem / 16);
    font-size: calc(14rem / 16);
    color: var(--color-darkest-grey);
    width: calc(100% - 300rem / 16);
    @media screen and (max-width: 767px) {
        width: calc(100% - 160rem / 16);
    }
}

.slider__slide-count {
    padding-right: calc(25rem / 16);

    @media screen and (max-width: 767px) {
        padding-right: calc(5rem / 16);
        line-height: 1;
    }
}
.slider__arrow-text.slick-disabled {
    opacity: 0.5;
}
.slider__arrow-text.slick-arrow {
    cursor: pointer;
    color: var(--color-primary);
    text-transform: uppercase;
    letter-spacing: calc(4rem/16);
    font-size: calc(15rem/16);
    padding-left: calc(30rem/16);
    padding-right: calc(30rem/16);
    @media screen and (max-width: 767px) {
        display: none !important;
    }
}
.slider .slick-slide > div {
    display: flex;
}