.nav-toggle {
    width: calc(50rem / 16);
    height: calc(55rem / 16);
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    color: inherit;
    @media screen and (min-width: 768px) {
        display: none;
    }
}
.nav-toggle__line {
    position: absolute;
    margin: 0 auto;
    height: calc(2rem / 16);
    width: calc(22rem / 16);
    background-color: currentColor;
    display: block;
    transition: 0.5s;
    transform-origin: center;
}
.nav-toggle__line:nth-child(1) {top: calc(16rem / 16);}
.nav-toggle__line:nth-child(2) {top: calc(24rem / 16);}
.nav-toggle__line:nth-child(3) {top: calc(32rem / 16);}

.nav-toggle.is-open .nav-toggle__line:nth-child(1) {
    transform: translateY(calc(8rem / 16)) rotate(-45deg);
}
.nav-toggle.is-open .nav-toggle__line:nth-child(2) {
    opacity: 0;
}
.nav-toggle.is-open .nav-toggle__line:nth-child(3) {
    transform: translateY(calc(-8rem / 16)) rotate(45deg);
}
.nav-toggle__text {
    font-size: calc(10rem/16);
    font-family: var(--font-default-bold);
    position: absolute;
    bottom:0;
}