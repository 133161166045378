.title-block--centered {
    text-align: center;
}
.title-block__title {
    color: var(--color-primary);
}
.title-block__sub-title {
    margin-bottom: calc(10rem / 16);
}
.title-block {
    margin-bottom: calc(45rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
    }
}
@media screen and (min-width: 768px) {
    .title-block__title, .title-block__sub-title {
        transform:translateY(calc(30rem/16)) scale(0.9);
        transition: transform 0.5s ease;
    }
    .title-block.is-in-viewport .title-block__title, .title-block.is-in-viewport .title-block__sub-title{
        transform:translateY(0) scale(1);
    }
}
