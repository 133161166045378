.experience-slider{
    margin-top: calc(80rem/16);
    @media screen and (min-width: 767px) {
        margin-top: calc(130rem/16);
    }
}
.experience-slider-item__sub-title {
    margin-bottom: calc(15rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(8rem/16);
    }
}
.experience-slider-item__title {
    color: var(--color-primary);
    margin-bottom: calc(30rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
        margin-bottom: calc(20rem/16);
    }
}
.experience-slider-item__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
}
.experience-slider-item__link {
    margin-top: calc(40rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(25rem/16);
    }

}
.experience-slider-item__content {
    padding-top: calc(15rem/16);
    max-width: calc(420rem/16);
}


.experience-slider .slick-list {
    overflow: visible;
}
.experience-slider .slick-slide {
    opacity:1 !important;
}
.experience-slider-item__media {
    position: relative;
    opacity:0;
    transform: rotate(0deg) scale(1) translateY(0%);
    transition: opacity 0.2s, transform 0.2s ease-in-out;
    transform-origin: left bottom;
    @media screen and (max-width: 767px) {
        width: 80%;
        margin: 0 auto;
    }
}
.experience-slider-item__media:before {
    content:'';
    position: absolute;
    width: 100%;
    left:0;
    top:0;
    z-index: 1;
    height: 100%;
    transition: opacity 0.2s ease;
    background-color: rgba(255,255,255,0.7);
}
.slick-current .experience-slider-item__media:before {
    opacity:0;
}
.experience-slider .slick-current + .slick-slide .experience-slider-item__media {
    opacity:1;
    z-index: 1;
    transform: rotate(-12deg) scale(.9) translateY(2%);
    transition: opacity 0.2s, transform 0.2s ease-in-out;

}
.experience-slider .slick-slide {
    z-index: 997 !important;
}
.experience-slider .slick-current + .slick-slide {
    z-index: 998 !important;
}
.experience-slider .slick-current.slick-slide {
    z-index: 999 !important;
}
.experience-slider .slick-current + .slick-slide + .slick-slide .experience-slider-item__media {
    opacity:1;
    z-index: 0;
    transition: opacity 0.3s 0.01s, transform 0.2s ease-in-out;
    transform: rotate(-24deg) scale(0.8) translateY(0%);
}
.experience-slider .slick-current .experience-slider-item__media {
    opacity:1;
    z-index: 2;
    transform: rotate(0deg) scale(1) translateY(0%);
}
.experience-slider-item__content {
    opacity: 0;
    transition: opacity 0.5s ease;
    @media screen and (max-width: 767px) {
        text-align:center;
        padding-left: calc(20rem/16);
        padding-right: calc(20rem/16);
    }
}
.experience-slider .slick-current .experience-slider-item__content {
    opacity:1;
}