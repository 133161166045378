:root {
    --simple-nav-level-0-item-spacing: calc(29rem / 16);
}

@media screen and (min-width: 768px) {
    .main-navbar:not(.main-navbar--transparent) .simple-nav--level-0:before, .main-navbar.is-affix .simple-nav--level-0:before {
        background-color:#fff;
        content:'';
        width: 100vw;
        left:0;
        z-index: -1;
        position: absolute;
        top:0;
        right:0;
        height: calc(78rem/16);
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    }
    .simple-nav--level-0 {
        background-color:#fff;
        display: flex;
        flex: auto;
        align-items: center;
        height: 100%;
        justify-content: center;
    }
    .main-navbar.main-navbar--transparent:not(.is-affix) .simple-nav--level-0 {
        background-color: transparent;
    }
    .simple-nav__list--level-0 {
        display: flex;
        flex-direction: row;
        margin-left: calc(var(--simple-nav-level-0-item-spacing) * -1);
        margin-right: calc(var(--simple-nav-level-0-item-spacing) * -1);
    }

    .simple-nav__item--level-0 {
        display: flex;
        flex-direction: column;
        flex: auto;
        position: relative;
    }

    .simple-nav__item.is-active > .simple-nav__item-content--level-0:after {
        content:'';
        height: calc(2rem/16);
        background-color: #fff;
        width: calc(50rem/16);
        margin: 0 auto;
        position: absolute;
        bottom:calc(20rem/16);
        left: 0;
        right:0;
    }
    .main-navbar:not(.main-navbar--transparent) .simple-nav__item.is-active > .simple-nav__item-content--level-0:after {
        background-color: var(--color-primary);
    }

    .simple-nav__item-content--level-0.is-open,
    .simple-nav__item-content--level-0:focus,
    .simple-nav__item-content--level-0:active,
    .simple-nav__item.is-open > .simple-nav__item-content {
        background-color: #fff;
        color: var(--color-primary);
    }
}

.simple-nav__item-content--level-0 {
    @media screen and (min-width: 768px) {
        flex-direction: column;
        flex: auto;
        padding: calc(27rem/16) var(--simple-nav-level-0-item-spacing);
        font-family: var(--font-default-bold);
        text-transform: uppercase;
        letter-spacing: calc(1rem/16);
        height: calc(78rem/16);
        transition: background-color 300ms, color 300ms ease;
        line-height: 1.9;
    }
    @media screen and (max-width: 1699px) and (min-width: 768px) {
        letter-spacing: 0;
        font-size: calc(14rem/16);
        padding: calc(27rem/16) calc(10rem/16);
    }
    @media screen and (max-width: 1099px) and (min-width: 768px) {
        font-size: calc(13rem/16);
    }

}