.contact-area__content{
    background: linear-gradient(60deg, #DA001C 35%, #9A0014 100%, #980013 100%);
    width: 100%;
/*    cursor: pointer;*/

    padding: calc(35rem/16) calc(25rem/16) calc(25rem/16);
    color: #FFF;
    line-height: 1.5;

    @media screen and (min-width: 768px){
        line-height: 1.3;
        flex-grow: 1;
        flex-basis: 40%;
        padding: calc(25rem/16) calc(30rem/16);
    }

    @media screen and (min-width: 1200px){
        line-height: 1.5;
        padding: calc(52rem/16) calc(65rem/16);
    }
}

.contact-area__img-wrapper{
    width: 100%;
    z-index: 1;

    @media screen and (min-width: 768px){
        flex-basis: 60%;
        flex-grow: 1;
    }
}

.contact-area__address{
    margin: calc(18rem/16) 0;

    @media screen and (min-width: 768px){
        margin: calc(8rem/16) 0;
    }

    @media screen and (min-width: 1200px){
        margin: calc(18rem/16) 0;
    }
}

.contact-area__link{
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(12rem/16);
    }

    @media screen and (min-width: 1200px){
        margin-top: calc(28rem/16);
    }
}

.contact-area__wrapper{
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.contact-area__img{
    transition: transform 0.3s;
}

.contact-area__img-wrapper:hover .contact-area__img, .contact-area__content:hover+.contact-area__img-wrapper .contact-area__img{
    transform: scale(1.02);
}

.contact-area__link{
    transition: transform 0.3s;
    transform: translateX(calc(-7rem/16));
}

.contact-area__link:hover{
    transform: translateX(calc(-5rem/16));
}