.social-teaser__content {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    color:#fff;
    background: linear-gradient(141.74deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    font-size: calc(30rem/16);
    padding: calc(15rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}
.social-teaser__img {
    transition: transform 0.3s ease;
}
.social-teaser:hover .social-teaser__img {
    transform: scale(1.05);
}