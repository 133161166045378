@font-face {
    font-family: 'dax-regular';
    src: url('/static/fonts/dax-regular.woff2') format('woff2'),
    url('/static/fonts/dax-regular.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'dax-bold';
    src: url('/static/fonts/dax-bold.woff2') format('woff2'),
    url('/static/fonts/dax-bold.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}
