.consent{

}
.consent--box-shadow {
    box-shadow: 0 0 60px 0 rgba(0,0,0,0.4);
    border-radius: 30px;
    overflow: hidden;
}
.consent__overlay{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(15rem/16);
    z-index: 1;
}
.consent__overlay--absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

