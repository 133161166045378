.anchor-nav {
    height: calc(40rem/16);
    position: fixed;
    top: calc(77rem/16);
    left: 14.625rem;
    z-index: 9;
    display: block;
    font-size: calc(14rem/16);
    font-family: var(--font-default);
    width: auto;
    @media screen and (max-width: 1599px) and (min-width: 768px) {
        left:0;
        width: 100%;
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.anchor-nav__list {
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: var(--color-primary-light);
    scrollbar-width: thin;
    padding-bottom: calc(0rem / 16);;

}
.anchor-nav__list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 calc(8rem / 16) rgba(0, 0, 0, 0.2);
    background-color: transparent;
    transition: all 120ms ease;
}
.anchor-nav__list::-webkit-scrollbar {
    width: calc(4rem / 16);
    height: calc(7rem / 16);
    background: var(--color-light-grey);
    transition: all 120ms ease;
}
.anchor-nav__list::-webkit-scrollbar-thumb {
    background-color: var(--color-primary-dark);
    transition: all 120ms ease;
}
.anchor-nav__item {
    height: calc(40rem/16);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    background-color: var(--color-dark-grey);
}
.anchor-nav__item:hover {
    color: var(--color-primary);
}

.anchor-nav__item:first-of-type {
    padding-left: calc(51rem/16);
    padding-left: calc(90rem/16);
    @media screen and (max-width: 1599px) and (min-width: 768px) {
        padding-left: calc(30rem/16);
    }
}
.anchor-nav__list:before {
    content:'';
    position: absolute;
    right:calc(-29rem/16);
    top:0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: calc(40rem/16) calc(30rem/16) 0 0;
    border-color: var(--color-dark-grey) transparent transparent transparent;
}
.anchor-nav__item.list-inline-item {
    padding-right: calc(20rem/16);
    margin-right:0;
}