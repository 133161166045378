.webcam-teaser {
    box-shadow: 0 5px 20px 0 rgba(0,0,0,0.05);
}
.webcam-teaser__content {
    background-color:#fff;
    padding-right: calc(15rem/16);
    height: calc(73rem/16);
}
.webcam-teaser__icon-wrapper {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    color:#fff;
    width: calc(45rem/16);
    font-size: calc(30rem/16);
    margin-right: calc(40rem/16);
    position: relative;
    background: linear-gradient(90deg,#da001c 35%,#9a0014 100%,#980013 0);
}
.webcam-teaser__icon-wrapper:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    right: calc(-40rem/16);
    border-color: transparent #980013;
    border-style: solid;
    border-width: 0 0 calc(73rem/16) calc(40rem/16);
}
.webcam-teaser__live {
    border-radius: 50%;
    background-color: var(--color-success);
    height: calc(10rem/16);
    width: calc(10rem/16);
    margin-right: calc(5rem/16);
}
.webcam-teaser__img {
    transition: transform 0.3s ease;
    object-fit: cover;
}
.webcam-teaser:hover .webcam-teaser__img {
    transform: scale(1.05);
}
.webcam-teaser__cookie-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: calc(24rem/16);
    z-index: 1;
    row-gap: calc(12rem/16);
}