.notification-header {
    background: var(--gradient-primary);
    color:#fff;
    width: 100%;
    z-index: 999;
    position: relative;
    padding: calc(17rem/16) calc(40rem/16);
    text-align:center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        padding: calc(5rem/16) calc(20rem/16) calc(5rem/16) calc(20rem/16);
        font-size: calc(12rem/16);
        text-align: center;
        flex-direction: column;
    }
}
.notification-header__icon {
    font-size: calc(30rem/16);
    margin-right: calc(10rem/16);
    @media screen and (max-width: 767px) {
        margin-right: 0;
        margin-bottom: calc(5rem/16);
    }
}
.notification-header__text {
    margin-right: calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-right: 0;
        margin-bottom: calc(5rem/16);
    }
}
.notification-header__text > p {
    margin-bottom:0;
}
.notification-header__close {
    position: absolute;
    right: calc(25rem/16);
    font-size: calc(30rem/16);
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
        right: calc(5rem/16);
        font-size: calc(15rem/16);
        top: calc(5rem/16);
        transform: translateY(0);
    }
}
.notification-header__link {
    @media screen and (max-width: 767px) {
        letter-spacing: 0;
    }
}