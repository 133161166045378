.midnightHeader.light {
    background: transparent;
    color: #fff;
    text-shadow: 0 0 calc(5rem/16) rgb(18, 18, 18);
}
.midnightHeader.default {
    background: transparent;
    color: black;
    text-shadow: 0 0 calc(5rem/16) rgb(255, 255, 255);
}
.midnightHeader .midnightInner {
    overflow: visible
}