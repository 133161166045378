
.partner-list__item{
    height: auto;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    padding-bottom: calc(40rem/16);

    @media screen and (min-width: 768px){
        height: calc(100rem/16);
        padding: 0;
    }
}

.partner-list__list-item:not(:last-child){
    margin-bottom: calc(24rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(20rem/16);
    }
}

.partner-list__title{
    background: linear-gradient(90deg, #DA001C 35%, #9A0014 100%, #980013 100%);
    position: relative;
    color: #FFF;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0 1rem calc(60rem/16);

    @media screen and (min-width: 768px){
        padding: 0 0 0 calc(16rem/16);
        height: 100%;
        width: 85%;
    }

    @media screen and (min-width: 1200px){
        padding: 0 0 0 calc(38rem/16);
        height: 100%;
        width: 85%;
    }
}


.partner-list__title::after{
    display: none;

    @media screen and (min-width: 768px){
        display: block;
        content: "";
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
        font-size: 2.5rem;
        right: calc(-55rem/16);
        border-top: 0 solid transparent;
        border-right: 0 solid transparent;
        border-bottom: calc(100rem / 16) solid transparent;
        border-left: calc(55rem / 16) solid var(--color-triangle-primary-dark);
    }
}

.partner-list_logo-wrapper{
    max-height: 100%;
    margin: 1.5rem calc(60rem/16);

    @media screen and (min-width: 768px){
        max-height: 100%;
        margin: auto 0 auto 2rem;
    }

    @media screen and (min-width: 1200px){
        margin: auto 2rem;
    }
}

.partner-list__address, .partner-list__info{
    margin: 0 calc(60rem/16);
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px){
        margin: auto 0 auto 0.5rem;
    }

    @media screen and (min-width: 1200px){
        margin: auto 0 auto 1rem;
        font-size: calc(16rem/16);
    }
}

.partner-list__address{
    @media screen and (max-width: 767px){
        margin: 0 calc(60rem/16) calc(20rem/16) calc(60rem/16);
    }
}