.footer__logos__list {
    padding: calc(28rem/16) 0;
    display: flex;
    @media screen and (max-width: 767px) {
        flex-flow: row wrap;
        padding: calc(10rem/16) 0;
        align-items: center;
        justify-content: center;
    }
}
.footer__logos__list-item + .footer__logos__list-item {
    @media screen and (min-width: 768px) {
        padding-left: calc(40rem/16);
    }
}
.footer__logos__list-item {
    filter: grayscale(100%);
    @media screen and (max-width: 767px) {
        width: calc(90rem/16);
        display: flex;
        padding: calc(10rem/16);
    }
}
.footer__logos__list-item:hover {
    filter: none;
}
.footer__logos__main-logo {
    max-height: calc(137rem/16);
    max-width: 100%;
    margin-top: -13%;
    @media screen and (max-width: 991px) and (min-width: 768px) {
        margin-top: -16%;
    }
    @media screen and (max-width: 1160px) and (min-width: 992px) {
        margin-top: -16%;
    }
    @media screen and (max-width: 767px) {
        margin-top: calc(-41rem/16);
        height: calc(143rem/16);
    }
}
.footer__logos__img {
    @media screen and (min-width: 768px) {
        height: calc(40rem/16);
    }
}